import type { DocumentReference } from 'firebase/firestore'
import { collection, getDocs, query, serverTimestamp, updateDoc, where } from 'firebase/firestore'
import { firestore } from '../firebaseCore'

export const missingCompaniesCollection = collection(
  firestore,
  'missingCompanies',
)

export function getMissingCompanies(companyRef: DocumentReference) {
  return getDocs(query(
    missingCompaniesCollection,
    where(
      'companyRef',
      '==',
      companyRef,
    ),
  ))
}

export function updatetMissingCompany(item: any, status: string, userReference: DocumentReference) {
  return updateDoc(
    item.reference,
    {
      status,
      lastUpdateByUser: userReference,
      lastUpdateTime: serverTimestamp(),
    },
  )
}
