import 'dayjs/locale/pl'
import dayjs from 'dayjs'
import { Timestamp } from 'firebase/firestore'

export const getNow = () => dayjs()

const localePL = 'pl'
export const timeFormat = 'HH:mm'
export const fullDateFormat = 'MMMM DD YYYY, HH:mm'
export const plDateFormat = 'DD MMMM'
export const dateFormat = 'YYYY-MM-DD'
export const dateSimpleFormat = 'DD-MM'
export const dayFormat = 'DD'
const smsFormat = 'MM-YYYY'
export const monthFormat = 'YYYY-MM'

export const toDayjsDate = (date: Timestamp) => dayjs(date.toDate())

export const toDateDayJs = (date: string | number | Date | dayjs.Dayjs | null) => dayjs(date)

export function dateToDateFormat(date: string | number | Date | dayjs.Dayjs | null) {
  return dayjs(
    date,
    dateFormat,
  )
}

export function getNextDay(date: string | number | Date) {
  const previousDay = new Date(date)
  previousDay.setDate(previousDay.getDate() + 1)
  return previousDay
}

export function mapTimeFromFirebaseFormat(time: Timestamp) {
  return time
    ? dayjs(time.toDate()).format(timeFormat)
    : null
}

export function mapMonthFromFirebaseFormat(date: Timestamp) {
  return date
    ? dayjs(date.toDate()).format(monthFormat)
    : null
}

export function mapToSimpleDateFromFirebase(time: Timestamp | null) {
  return time
    ? dayjs(time.toDate()).format(dateFormat)
    : null
}

export function mapToSimpleDayFromFirebase(time: Timestamp) {
  return time
    ? dayjs(time.toDate()).format(dayFormat)
    : null
}

export function mapToDateFromFirebase(time: Timestamp) {
  return time
    ? dayjs(time.toDate()).format(dateSimpleFormat)
    : null
}

export function mapDateFromFirebaseFormat(time: any) {
  return time
    ? dayjs(time.toDate()).locale(localePL).format(fullDateFormat)
    : null
}

export function getTimeAndDate(date: Timestamp) {
  const formatteDate = dayjs(date.toDate())
  return [
    formatteDate.format(dateFormat),
    formatteDate.format(timeFormat),
  ]
}

export const dateToDayJS = (date: any) => dayjs(date)

export function createSMSDate() {
  return dayjs().format(smsFormat).toString()
}

export const getMaxMonth = () => dayjs().format(monthFormat)
export function getMaxPrevMonth() {
  return dayjs().subtract(
    1,
    'month',
  ).format(monthFormat)
}

export function getStartOfDay(date: Date) {
  const startOfDay = new Date(date)
  startOfDay.setHours(0, 0, 0, 0)
  return startOfDay
}

export function getEndOfDay(date: Date) {
  const endOfDay = new Date(date)
  endOfDay.setHours(23, 59, 59, 999)
  return endOfDay
}

export const formatTomorrowOrderTIme = (tomorrowOrderTime: any) => dayjs(tomorrowOrderTime?.toDate()).hour() || 17

export function formatHours(hours: any, minutes: any) {
  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  return `${formattedHours}:${formattedMinutes}`
}

export function getSmallerTime(date1: Date, date2: Date) {
  const hours1 = date1.getHours()
  const hours2 = date2.getHours()

  const minutes1 = date1.getMinutes()
  const minutes2 = date2.getMinutes()

  if (hours1 < hours2) {
    return formatHours(hours1, minutes1)
  }
  else if (hours1 > hours2) {
    return formatHours(hours2, minutes2)
  }

  if (minutes1 < minutes2)
    return formatHours(hours1, minutes1)

  else if (minutes1 > minutes2)
    return formatHours(hours2, minutes2)

  return formatHours(hours1, minutes1)
}
export function dateToYearMonthDay(date: Date) {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${year}-${month}-${day}`
}

export function dateWithHours(timeString: string, date: Date) {
  const newDate = new Date(date)
  const [hours, minutes] = timeString.split(':').map(Number)
  newDate.setHours(hours, minutes, 0, 0)
  return newDate
}

export function dateToTimestamp(date: Date) {
  return new Timestamp(Math.floor(date.getTime() / 1000), 0)
}

export function timestampToTime(date: any) {
  const myDate = new Date(date)
  const hours = myDate.getHours().toString().padStart(2, '0')
  const minutes = myDate.getMinutes().toString().padStart(2, '0')
  return `${hours}:${minutes}`
}

export function isToday(date: Date) {
  const today = new Date()

  return (date.getDate() === today.getDate())
    && (date.getMonth() === today.getMonth())
    && (date.getFullYear() === today.getFullYear())
}

export function isTomorrow(date: Date) {
  const tomorrow = new Date()
  tomorrow.setDate(new Date().getDate() + 1)

  return (
    date.getFullYear() === tomorrow.getFullYear()
    && date.getMonth() === tomorrow.getMonth()
    && date.getDate() === tomorrow.getDate()
  )
}
