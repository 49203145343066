import type { DocumentData, Unsubscribe } from 'firebase/firestore'
import { addDoc, getDocs, increment, onSnapshot, serverTimestamp, updateDoc, writeBatch } from 'firebase/firestore'
import {
  collectionRoutes,
  getRoutesByCities,
  getRoutesQuery,
  ordersToUpdate,
} from '../firestoreWrappers'
import { isBiller, isGetDeliver, isManagerOrDeliver, isOwner } from '../helpers/roles'
import { errorDefault } from '../helpers/snackbar'
import { firestore } from '../firebaseCore'
import type { RouteModel } from '../models/RouteModel'
import { mapRoute } from '../models/RouteModel'
import { getDeliversSellsLast2Months } from './firestoreDeliversSells'
import store from '.'
import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'

export const useRouteStore = defineStore('routes', () => {
  const routes: Ref<RouteModel[] | null> = ref(null)
  const fetchLoading: Ref<boolean> = ref(false)
  const fetchError: Ref<unknown | null> = ref(null)
  const createLoading: Ref<boolean> = ref(false)
  const createError: Ref<unknown | null> = ref(null)
  const updateLoading: Ref<boolean> = ref(false)
  const updateError: Ref<unknown | null> = ref(null)
  const unsubscribe: Ref<Unsubscribe | null> = ref(null)

  function resetState() {
    if (unsubscribe.value)
      unsubscribe.value()

    routes.value = null
    fetchLoading.value = false
    fetchError.value = null
    createLoading.value = false
    createError.value = null
    updateLoading.value = false
    updateError.value = null
    unsubscribe.value = null
  }

  // @ts-expect-error
  function getRoutes({ userData, user }) {
    fetchError.value = null
    fetchLoading.value = true

    if (unsubscribe.value) {
      unsubscribe.value()
      unsubscribe.value = null
    }

    const onSuccess = (arg0: DocumentData) => {
      const byName = (item1: any, item2: any) => item2?.name - item1?.name
      let data = arg0.docs.map(mapRoute).sort(byName)
      if (isGetDeliver(userData.role) || isBiller(userData.role))
        // @ts-expect-error
        data = (data || []).filter(item => (item?.visibilityAccess || []).map(item1 => item1?.id).includes(userData.reference.id))

      fetchLoading.value = false
      fetchError.value = null
      routes.value = data
    }

    const onError = (error: unknown) => {
      console.error(error)
      if ((error as any).code !== 'permission-denied') {
        fetchLoading.value = false
        fetchError.value = error

        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }
    }

    if (isOwner(user.displayName) || isManagerOrDeliver(user.displayName)) {
      const query = isOwner(user.displayName)
        ? getRoutesQuery(userData.company)
        : getRoutesByCities(
            userData.company,
            userData.profile.cities,
          )

      const unsubscribeNew = onSnapshot(
        query,
        onSuccess,
        onError,
      )

      unsubscribe.value = unsubscribeNew
    }
    else {
      onError('Wrong site')
    }
  }
  // @ts-expect-error
  function createRoute({ 'route': routeInput, userData }) {
    createError.value = null
    createLoading.value = true

    const route = routeInput
    route.company = userData.company
    route.lastUpdateByUser = userData.reference
    route.lastUpdateTime = serverTimestamp()

    const onSuccess = (refArg: any) => {
      route.reference = refArg
      createLoading.value = false
      createError.value = null
    }

    const onError = (error: unknown) => {
      console.error(error)
      createLoading.value = false
      createError.value = error
    }

    addDoc(
      collectionRoutes,
      {
        ...route.toMap(),
        createdByUser: userData.reference,
      },
    ).then(onSuccess).catch(onError)
  }
  // @ts-expect-error
  async function updateRoute({ 'route': routeInput, deliver, subDeliver, changeDeliver, userData, suppliers }) {
    updateError.value = null
    updateLoading.value = true

    const route = routeInput

    route.company = userData.company
    route.lastUpdateByUser = userData.reference
    route.lastUpdateTime = serverTimestamp()

    const batch = writeBatch(firestore)

    batch.update(
      route.reference,
      {
        ...route.toMapFirebase(),
        lastUpdateByUser: userData.reference,
        lastUpdateTime: serverTimestamp(),

      },
    )

    const oldRoute = routes.value?.find(item => item?.reference?.id === route.reference.id)
    // @ts-expect-error
    const added = route.visibilityAccess.filter(item => !oldRoute?.visibilityAccess.includes(item))
    // @ts-expect-error
    const removed = oldRoute?.visibilityAccess.filter(item => !route.visibilityAccess.includes(item))

    const itemsToUpdate = await getDeliversSellsLast2Months(
      userData.company,
      route.reference,
    )

    if (!itemsToUpdate.empty) {
      itemsToUpdate.docs.forEach((item) => {
        // @ts-expect-error
        let dataToUpdate = item.data().visibilityAccess.filter(item4 => item4) || []
        if (added.length > 0) {
          dataToUpdate = [
            ...dataToUpdate,
            ...added,
          ]
        }
        // @ts-expect-error
        if (removed.length > 0) {
          // @ts-expect-error
          const tmp = removed.map(item2 => item2.id)
          // @ts-expect-error
          dataToUpdate = dataToUpdate.filter(item3 => !tmp.includes(item3.id))
        }
        const result = [...new Set(dataToUpdate)] || []
        batch.update(
          item.ref,
          {
            visibilityAccess: result,
          },
        )
      })
    }
    // @ts-expect-error
    const updateDeliverId = (supplier) => {
      if (supplier && supplier.reference) {
        const deliverID = route.isSubDeliver
          ? subDeliver.reference
          : deliver.reference

        batch.update(
          supplier.reference,
          {
            deliverID,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )
      }
    }

    if (suppliers)
      suppliers.forEach(updateDeliverId)

    const onError = (error: unknown) => {
      console.error(error)
      updateLoading.value = false
      updateError.value = error
    }

    const onSuccess = () => {
      updateLoading.value = false
      updateError.value = null

      const routeId = route.reference.id
      // @ts-expect-error
      const mapRouteData = ({ 'reference': { id } }) => id == routeId
      // @ts-expect-error
      const routeIndex = routes.value?.findIndex(mapRouteData)
      // @ts-expect-error
      routes.value[routeIndex] = route
    }

    const onSuccess1 = (arg0: DocumentData) => {
      // @ts-expect-error
      const mapOrders = (order) => {
        batch.update(
          order.ref,
          {
            deliver: changeDeliver,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )
      }
      arg0.docs.map(mapOrders)

      batch.commit()
        .then(onSuccess)
        .catch(onError)
    }

    if (changeDeliver) {
      getDocs(ordersToUpdate(
        userData.company,
        route.reference,
      ))
        .then(onSuccess1)
        .catch(onError)
    }
    else {
      batch.commit()
        .then(onSuccess)
        .catch(onError)
    }
  }
  // @ts-expect-error
  function decreasePendingOrders({ userData, route }) {
    const onSuccess = () => {
      // @ts-expect-error
      const updateOnlySelectedRoute = (routeData) => {
        if (routeData.reference.id === route.reference.id) {
          const tmpRoute = routeData
          tmpRoute.pendingOrders -= 1
          return tmpRoute
        }
        return routeData
      }
      // @ts-expect-error todo
      routes.value = routes.value?.map(updateOnlySelectedRoute)
    }

    const onError = (error: unknown) => {
      console.error(error)
    }

    updateDoc(
      route.reference,
      {
        pendingOrders:
        increment(-1),
        lastUpdateByUser: userData.reference,
        lastUpdateTime: serverTimestamp(),
      },
    )
      .then(onSuccess)
      .catch(onError)
  }
  // @ts-expect-error
  function updateRouteData(routesArg) {
    fetchLoading.value = false
    fetchError.value = null
    routes.value = routesArg
  }
  // @ts-expect-error
  function isEarningsLikeWroForPoz(route) {
    if (!route)
      return false

    const foundRoute = routes.value?.find(item => item?.reference?.id === route.id)

    if (!foundRoute)
      return false

    return foundRoute.countEarningsLikeWroForPoz || false
  }

  return {
    routes,
    fetchLoading,
    fetchError,
    createLoading,
    createError,
    updateLoading,
    updateError,
    unsubscribe,

    resetState,
    getRoutes,
    createRoute,
    updateRoute,
    decreasePendingOrders,
    updateRouteData,
    isEarningsLikeWroForPoz,

  }
})
