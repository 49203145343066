import type { DocumentData, DocumentReference } from 'firebase/firestore'

export class MenuCategoryModel {
  name: string | null
  nameEN: string | null
  isActive: boolean
  orderOnDay: string | null
  id: string | null
  priority: string | null
  preferenceID: string | null
  menuInactive: string | null
  menuCategoryRef: DocumentReference | null

  constructor(data: Partial<MenuCategoryModel>, menuCategoryRef: DocumentReference | null) {
    this.name = data.name || null
    this.nameEN = data.nameEN || null
    this.isActive = data.isActive || false
    this.orderOnDay = data.orderOnDay || null
    this.id = data.id || null
    this.priority = data.priority || null
    this.preferenceID = data.preferenceID || null
    this.menuInactive = null

    this.menuCategoryRef = menuCategoryRef || null
  }

  toMap() {
    const result: Partial<MenuCategoryModel> = {
      name: this.name?.trim() || null,
      nameEN: this.nameEN?.trim() || null,
      isActive: this.isActive || false,
      orderOnDay: this.orderOnDay || null,
      id: this.id,
    }
    if (this.preferenceID)
      result.preferenceID = this.preferenceID

    if (this.priority)
      result.priority = this.priority

    return result
  }
}

export function mapMenuCategory(menuCategoriesDoc: DocumentData) {
  return (menuCategoryDoc: Partial<MenuCategoryModel>) => new MenuCategoryModel(
    menuCategoryDoc,
    menuCategoriesDoc.ref,
  )
}

export function mapMenuCategories(menuCategoriesDoc: DocumentData) {
  return menuCategoriesDoc.data()
    .menuCategories
    .map(mapMenuCategory(menuCategoriesDoc))
}
