<script setup lang="ts">
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import { mdiClose, mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { useVuetify } from '../../useVuetify'
import { useI18n } from '../../useI18n'
import { useDrawerStore } from '~/store/drawer'

const drawerStore = useDrawerStore()
const { visibleLanding } = storeToRefs(drawerStore)

const isDark = ref(false)

const { isPhone, setTheme } = useVuetify()
const { setLocale, getLocale } = useI18n()

onMounted(() => {
  const mq = window.matchMedia('(prefers-color-scheme: dark)')

  const dark
    = localStorage.dark == null ? mq.matches : JSON.parse(localStorage.dark)
  isDark.value = dark
})

function toggleLang(key: string) {
  switch (key) {
    case 'PL':
      setLocale('pl')
      break
    case 'EN':
      setLocale('en')
      break

    default:
      setLocale('pl')
      break
  }
}

function toggleTheme2() {
  setTheme(!isDark.value)
  localStorage.dark = JSON.stringify(!isDark.value)
  isDark.value = !isDark.value
}

function toggleDrawer() {
  drawerStore.toggleDrawerLanding()
}

const mapLocale = computed(() => {
  switch (getLocale.value) {
    case 'pl':
      return 'PL'
    case 'en-US':
      return 'EN'
    case 'en-GB':
      return 'EN'

    default:
      return 'PL'
  }
})

// @ts-expect-error checked
const { $root } = getCurrentInstance().proxy
// @ts-expect-error checked
const { _router } = $root

function closeDrawerAndRedirect(url: string) {
  drawerStore.toggleDrawerLanding()
  _router.push(url)
}
</script>

<template>
  <div>
    <v-navigation-drawer

      app
      class="d-print-none h-100% xl:hidden"
      right
      :value="visibleLanding"
      width="100%"
    >
      <v-list>
        <v-list-item>
          <router-link
            class="flex"
            to="/"
          >
            <img
              height="40"
              src="/landing-page/logo.webp"
            >

            <h3 class="pt-1 px-2">
              {{ $t("App.title1") }}
            </h3>
          </router-link>

          <v-spacer />

          <v-btn
            icon
            x-large
            @click="toggleDrawer"
          >
            <v-icon large>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-list-item>

        <v-list-item @click="closeDrawerAndRedirect('/')">
          {{ $t("LandingPage.start") }}
        </v-list-item>

        <v-divider />

        <v-divider />

        <v-list-item @click="closeDrawerAndRedirect('/gastronomy')">
          {{ $t("LandingPage.gastronomy") }}
        </v-list-item>

        <v-divider />

        <v-list-item @click="closeDrawerAndRedirect('/transport')">
          {{ $t("LandingPage.transport") }}
        </v-list-item>

        <v-divider />

        <v-list-item @click="closeDrawerAndRedirect('/benefit')">
          {{ $t("LandingPage.benefit") }}
        </v-list-item>

        <v-divider />

        <v-list-item @click="closeDrawerAndRedirect('/about')">
          {{ $t("LandingPage.about") }}
        </v-list-item>

        <v-divider />

        <v-list-item @click="closeDrawerAndRedirect('/contact')">
          {{ $t("LandingPage.contact") }}
        </v-list-item>

        <v-divider />

        <v-list-item @click="closeDrawerAndRedirect('/login')">
          {{ $t("LandingPage.login") }}
        </v-list-item>

        <v-divider />

        <v-list-item @click="toggleTheme2">
          {{ $t("darkTheme") }}
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <router-link
        class="flex"
        to="/"
      >
        <img
          height="40"
          src="/landing-page/logo.webp"
        >

        <h3 class="pt-1 px-2">
          {{ $t("App.title1") }}
        </h3>
      </router-link>

      <v-btn
        class="d-lg-flex d-none mx-1"
        color="primary"
        text
        to="/"
      >
        <span>
          {{ $t("LandingPage.start") }}
        </span>
      </v-btn>

      <v-btn
        class="d-lg-flex d-none mx-1"
        color="primary"
        text
        to="/gastronomy"
      >
        <span>
          {{ $t("LandingPage.gastronomy") }}
        </span>
      </v-btn>

      <v-btn
        class="d-lg-flex d-none mx-1"
        color="primary"
        text
        to="/transport"
      >
        <span>
          {{ $t("LandingPage.transport") }}
        </span>
      </v-btn>

      <v-btn
        class="d-lg-flex d-none mx-1"
        color="primary"
        text
        to="/benefit"
      >
        <span>
          {{ $t("LandingPage.benefit") }}
        </span>
      </v-btn>

      <v-btn
        class="d-lg-flex d-none mx-1"
        color="primary"
        text
        to="/about"
      >
        <span>
          {{ $t("LandingPage.about") }}
        </span>
      </v-btn>

      <v-btn
        class="d-lg-flex d-none mx-1"
        color="primary"
        text
        to="/contact"
      >
        <span>
          {{ $t("LandingPage.contact") }}
        </span>
      </v-btn>

      <v-spacer />

      <v-select
        id="lang"
        class="max-width"
        dense
        flat
        full-width
        hide-details
        :items="[
          'PL',
          'EN'
        ]"
        :value="mapLocale"
        width="10px"
        @change="toggleLang"
      />

      <v-btn
        class="d-lg-flex d-none mx-2"
        color="primary"
        text
        to="/login"
      >
        {{ $t("LandingPage.login") }}
      </v-btn>

      <ToggleDrawerLanding
        class="d-flex d-lg-none"
        :dark="isDark"
      />
    </v-app-bar>

    <v-btn
      v-if="!isPhone"
      class="v-btn-align"
      color="primary"
      :dark="!isDark"
      height="60px"
      style="z-index: 9;"
      width="60px"
      @click="toggleTheme2"
    >
      <v-icon>
        {{ isDark
          ? mdiWeatherSunny
          : mdiWeatherNight }}
      </v-icon>
    </v-btn>
  </div>
</template>

<style scoped>
.v-btn {
  text-transform: capitalize;
}
a {
  text-decoration: none;
}
.v-btn-align {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}
.max-width {
  max-width: 65px;
}
.max-height {
  max-height: 460px;
}
</style>
