import { applyActionCode, confirmPasswordReset, signInWithEmailAndPassword, signOut, verifyPasswordResetCode } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import { COMPNAY_ROLES, isDeliver, ROLE } from '../helpers/roles'
import {
  collectionUsers,
  getCustomer,
  getDeliver,
  getManager,
  getModerator,
  getOwner,
} from '../firestoreWrappers'
import { auth } from '../firebaseCore'
import { mapToModel } from '../models/ManagerModel'
import router from '../router'
import { useSuppliersSubsidyStore } from './suppliersSubsidy'
import { useStatisticsStore } from './statistics'
import store, { } from '.'
import { useNewsStore } from '~/store/newsStore'
import { useMissingCompaniesStore } from './missingCompanies'
import { useCompaniesStore } from './companies'
import { useMessagesStore } from './messages'
import { useReportBugOrSuggestionStore } from './reportBugOrSuggestion'
import { useCouponsStore } from './coupons'
import { useInvoicesStore } from './invoices'
import { useManagerRoutesStore } from './managerRoutes'
import { useOrdersStatisticStore } from './ordersStatistic'
import { usePromoCodeStatisticsStore } from './promoCodeStatistics'
import { useOnlineTransactionReportsStore } from './onlineTransactionReports'
import { useRouteStore } from './routes'
import { useCompanyStore } from './company'
import { useEmployeesStore } from './employees'
import { useMenusStore } from './menus'

export function initialState() {
  return {
    user: null,
    userData: null,
    error: null,
    loading: false,
  }
}

function getRoute(role: string) {
  switch (role) {
    case ROLE.DELIVER:
      return '/routes'
    case ROLE.OWNER:
      return '/company'
    case ROLE.MANAGER:
      return '/routes'
    case ROLE.MODERATOR:
      return '/moderator'
    default:
      return '/sign-out'
  }
}

function getRouteUser(role: string) {
  switch (role) {
    case ROLE.MANAGER:
      return '/subsidyBudget'
    default:
      return '/sign-out'
  }
}

const getUser = (uid: string) => getDoc(doc(collectionUsers, uid))

export default {
  namespaced: true,
  state: {
    user: null,
    userData: null,
    error: null,
    loading: false,
  },
  actions: {
  // @ts-expect-error
    resetState({ commit }) {
      commit('RESET_STATE')
    },
    // @ts-expect-error
    checkUserData({ dispatch }, user) {
      if (COMPNAY_ROLES.includes(user?.displayName)) {
        // @ts-expect-error
        const logIn = (userData, currentUserData) => {
          if (currentUserData.data()?.isActive) {
            dispatch(
              'logIn',
              {
                user: userData,
                currentUserData,
              },
            )
          }
          else {
            router.push('error-active-account')
            dispatch('signOut')
          }
        }

        // @ts-expect-error
        const handleDeliver = authData => (deliverData) => {
          if (!deliverData.exists() || isDeliver(deliverData.data().role)) {
            router.push('error-deliver')
            dispatch('signOut')
          }
          else {
            logIn(
              authData,
              deliverData,
            )
          }
        }

        // @ts-expect-error
        const handleUserLogin = authData => deliverData => logIn(
          authData,
          deliverData,
        )

        let request
        switch (user.displayName) {
          case ROLE.OWNER:
            request = getOwner(user.uid)
            break
          case ROLE.GET_DELIVER:
            request = getDeliver(user.uid)
            break
          case ROLE.DELIVER:
            request = getDeliver(user.uid)
            break
          case ROLE.MANAGER:
            request = getManager(user.uid)
            break
          case ROLE.MODERATOR:
            request = getModerator(user.uid)
            break
          case ROLE.CUSTOMER:
            request = getCustomer(user.uid)
            break
          default:
            dispatch('signOut')
            break
        }

        // @ts-expect-error
        const handleUserData = (userDataResponse) => {
          if (user?.displayName === ROLE.DELIVER)
            handleDeliver(user)(userDataResponse)
          else
            handleUserLogin(user)(userDataResponse)
        }

        // @ts-expect-error
        request.then(handleUserData).catch(() => {
          router.push('error-active-account')
          dispatch('signOut')
        })
      }
      else {
        dispatch('signOut')
      }
    },
    // @ts-expect-error
    loginWithPassword({ commit }, { email, password }) {
      commit('PASSWORD_LOGIN_REQUEST')

      // @ts-expect-error
      const onLoginSuccess = ({ user: authUser }) => {
        commit('PASSWORD_LOGIN_SUCCESS')

        // update display name
        // authUser.updateProfile({
        //   displayName: 'customer',
        // })

        if (authUser.displayName == 'customer')
          getUser(authUser.uid).then(userResponse => router.push(getRouteUser(userResponse.data()?.role)))

        else
          router.push(getRoute(authUser.displayName))
      }

      const onLoginError = (loginError: unknown) => {
        console.error(loginError)
        console.error(loginError)
        commit(
          'PASSWORD_LOGIN_FAILURE',
          loginError,
        )
      }

      signInWithEmailAndPassword(
        auth,
        email,
        password,
      )
        .then(onLoginSuccess)
        .catch(onLoginError)
    },
    // @ts-expect-error
    logIn({ commit, dispatch }, { user: loginUser, currentUserData: loginUserData }) {
      const mappedUserData = mapToModel(loginUserData, loginUser.displayName)

      if (!loginUserData.data()?.isActive) {
        router.push('error-active-account')
        dispatch('signOut')
        return
      }

      // @ts-expect-error
      if (mappedUserData.company) {
        // @ts-expect-error
        const onCompanyFetch = (companyResponse) => {
          const companyStore = useCompanyStore()

          companyStore.setCompany(
            companyResponse,
          )
          commit(
            'SET_USER',
            {
              user: loginUser,
              userData: mappedUserData,
            },
          )
        }

        // @ts-expect-error
        getDoc(mappedUserData.company).then(onCompanyFetch)
      }
      else {
        commit(
          'SET_USER',
          {
            user: loginUser,
            userData: mappedUserData,
          },
        )
      }
    },
    // @ts-expect-error
    resetPassword({ commit }, { code, password }) {
      commit('RESET_PASSWORD_REQUEST')

      const onResetSuccess = () => {
        commit('RESET_PASSWORD_SUCCESS')
      }

      const onResetError = (resetError: unknown) => {
        console.error(resetError)
        console.error(resetError)
        commit(
          'RESET_PASSWORD_FAILURE',
          resetError,
        )
      }

      const onCodeValid = () => {
        confirmPasswordReset(
          auth,
          code,
          password,
        )
          .then(onResetSuccess)
          .catch(onResetError)
      }

      verifyPasswordResetCode(
        auth,
        code,
      ).then(onCodeValid).catch(onResetError)
    },
    // @ts-expect-error
    verifyEmail({ commit }, { code }) {
      commit('VERIFY_EMAIL_REQUEST')

      const onVerifySuccess = () => {
        commit('VERIFY_EMAIL_SUCCESS')
      }

      const onVerifyError = (verifyError: unknown) => {
        console.error(verifyError)
        console.error(verifyError)
        commit(
          'VERIFY_EMAIL_FAILURE',
          verifyError,
        )
      }

      applyActionCode(
        auth,
        code,
      )
        .then(onVerifySuccess)
        .catch(onVerifyError)
    },
    // @ts-expect-error
    updateUserData({ commit, 'state': { user: currentUser } }, newUserData) {
      commit(
        'SET_USER',
        {
          user: currentUser,
          userData: newUserData,
        },
      )
    },
    // @ts-expect-error
    signOut({ commit }) {
      const onSuccess = () => {
        commit(
          'SET_USER',
          {
            user: null,
            userData: null,
          },
        )
        store.dispatch('app/resetState')
        store.dispatch('shared/resetState')
        store.dispatch('orders/resetState')
        store.dispatch('suppliers/resetState')

        useMenusStore().resetState()
        useEmployeesStore().resetState()
        useCompanyStore().resetState()
        useReportBugOrSuggestionStore().resetState()
        useMessagesStore().resetState()
        useCouponsStore().resetState()
        useInvoicesStore().resetState()
        useCompaniesStore().resetState()
        useOrdersStatisticStore().resetState()
        useManagerRoutesStore().resetState()
        usePromoCodeStatisticsStore().resetState()
        useMissingCompaniesStore().resetState()
        useNewsStore().resetState()
        useSuppliersSubsidyStore().resetState()
        useStatisticsStore().resetState()
        useOnlineTransactionReportsStore().resetState()
        useRouteStore().resetState()
      }
      signOut(auth).then(onSuccess)
    },
  },
  mutations: {
    RESET_STATE(_state: any) {
      Object.assign(
        _state,
        initialState(),
      )
    },
    PASSWORD_LOGIN_REQUEST(_state: any) {
      _state.error = null
      _state.loading = true
    },
    PASSWORD_LOGIN_SUCCESS(_state: any, userData: any) {
      _state.userData = userData
      _state.loading = false
    },
    PASSWORD_LOGIN_FAILURE(_state: any, error: any) {
      _state.loading = false
      _state.error = error
    },
    SET_USER_COPY(_state: any, { user }: any) {
      _state.user = user
    },
    SET_USER(_state: any, { user, userData }: any) {
      _state.user = user
      _state.userData = userData
    },
    SET_USER_DATA_REQUEST(_state: any) {
      _state.error = null
      _state.loading = true
    },
    SET_USER_DATA_SUCCESS(_state: any, userData: any) {
      _state.userData = userData
      _state.loading = false
    },
    SET_USER_DATA_FAILURE(_state: any, error: any) {
      _state.loading = false
      _state.error = error
    },
    RESET_PASSWORD_REQUEST(_state: any) {
      _state.error = null
      _state.loading = true
    },
    RESET_PASSWORD_SUCCESS(_state: any) {
      _state.loading = false
    },
    RESET_PASSWORD_FAILURE(_state: any, error: any) {
      _state.loading = false
      _state.error = error
    },
    VERIFY_EMAIL_REQUEST(_state: any) {
      _state.error = null
      _state.loading = true
    },
    VERIFY_EMAIL_SUCCESS(_state: any) {
      _state.loading = false
    },
    VERIFY_EMAIL_FAILURE(_state: any, error: any) {
      _state.loading = false
      _state.error = error
    },
  },
}
