import type { Timestamp } from 'firebase/firestore'

export interface IAgreement {
  name: string | null
  acceptedBy: string | null
  status: string | null
  activeFile: string | null
  timeToAccept: Timestamp | null
  rejectedMessage: string | null
}

export class AgreementModel implements IAgreement {
  name: string | null
  acceptedBy: string | null
  status: string | null
  activeFile: string | null
  timeToAccept: Timestamp | null
  rejectedMessage: string | null

  constructor(data: Partial<IAgreement>) {
    this.name = data.name || null
    this.acceptedBy = data.acceptedBy || null
    this.status = data.status || null
    this.activeFile = data.activeFile || null
    this.timeToAccept = data.timeToAccept || null
    this.rejectedMessage = data.rejectedMessage || ''
  }

  toMap() {
    return {
      acceptedBy: this.acceptedBy,
      status: this.status,
      activeFile: this.activeFile,
      timeToAccept: this.timeToAccept,
      rejectedMessage: this.rejectedMessage,
    }
  }
}

export const AGREEMENT_STATUS = {
  waitingFirmAccept: 'waitingFirmAccept',
  waitingModeratorAccept: 'waitingModeratorAccept',
  completed: 'completed',
  rejected: 'rejected',
}
