import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { DocumentData, DocumentReference, Unsubscribe } from 'firebase/firestore'
import { serverTimestamp, updateDoc } from 'firebase/firestore'
import type { MessageModel } from '../models/MessageModel'
import { mapMessages } from '../models/MessageModel'
import { getCompanyCateringOrdersQuery, getMessagesQuery, getVisibleToDoCateringOrdersQuery, updateMessage } from '../firebase/firestoreMessages'
import store from '.'
import { errorDefault } from '../helpers/snackbar'

function onErrorShared(error: unknown) {
  console.error(error)
  store.dispatch(
    'snackbar/showSnackbar',
    errorDefault(error),
  )
}

export const useMessagesStore = defineStore('messages', () => {
  const data: Ref<MessageModel[] | null> = ref(null)
  const cateringOrdersToDo: Ref<MessageModel[]> = ref([])
  const cateringOrdersCompany: Ref<MessageModel[]> = ref([])
  const loading: Ref<boolean | null> = ref(null)
  const unsubscribe1: Ref<Unsubscribe | null> = ref(null)
  const unsubscribe2: Ref<Unsubscribe | null> = ref(null)

  const resetState = () => {
    data.value = null
    cateringOrdersToDo.value = []
    cateringOrdersCompany.value = []
    loading.value = null
    unsubscribe1.value = null
    unsubscribe2.value = null
  }

  const getMessages = () => {
    loading.value = true
    store.dispatch('shared/init')

    getMessagesQuery()
      .then((arg0: DocumentData) => {
        data.value = arg0.docs.map(mapMessages)
        store.dispatch('shared/success')
        loading.value = false
      })
      .catch((error: unknown) => {
        console.error(error)
        onErrorShared(error)
        loading.value = false
      })
  }

  const getCateringOrdersToDo = () => {
    loading.value = true
    store.dispatch('shared/init')

    if (unsubscribe1.value) {
      unsubscribe1.value()
      unsubscribe1.value = null
    }

    const onSuccess = (arg0: DocumentData) => {
      cateringOrdersToDo.value = arg0.docs.map(mapMessages)
      store.dispatch('shared/success')
      loading.value = false
    }

    const onError = (error: unknown) => {
      console.error(error)
      onErrorShared(error)
      loading.value = false
    }

    unsubscribe1.value = getVisibleToDoCateringOrdersQuery(onSuccess, onError)
  }

  const getCompanyCateringOrders = (company: DocumentReference) => {
    loading.value = true
    store.dispatch('shared/init')

    const onSuccess = (arg0: DocumentData) => {
      cateringOrdersCompany.value = arg0.docs.map(mapMessages)
      store.dispatch('shared/success')
      loading.value = false
    }

    const onError = (error: unknown) => {
      console.error(error)
      onErrorShared(error)
      loading.value = false
    }

    unsubscribe2.value = getCompanyCateringOrdersQuery(company, onSuccess, onError)
  }

  const updateMessageState = (item: MessageModel, status: string, userDataRef: DocumentReference) => {
    const compareRefId = (element1: MessageModel, element2: MessageModel) => element1?.reference?.id === element2?.reference?.id
    const updateStatus = (itemFunc: MessageModel, statusFunc: string) => (message: MessageModel) => (
      compareRefId(message, itemFunc)
        ? { ...message, status: statusFunc }
        : message
    )

    loading.value = true
    store.dispatch('shared/init')

    updateMessage(item.reference as DocumentReference, status, userDataRef)
      .then(() => {
        // @ts-expect-error
        data.value = data.value?.map(updateStatus(item, status)) || []
        store.dispatch('shared/success')
        loading.value = false
      })
      .catch((error: unknown) => {
        console.error(error)
        onErrorShared(error)
        loading.value = false
      })
  }

  const updateCateringOrder = (item: MessageModel, status: string, company: string, userDataRef: DocumentReference) => {
    loading.value = true

    const updateData = {
      status,
      company: status === 'toDo' ? null : company,
      isVisible: status === 'toDo',
      lastUpdateByUser: userDataRef,
      lastUpdateTime: serverTimestamp(),
    }

    updateDoc(item.reference as DocumentReference, updateData)
      .then(() => {
        store.dispatch('shared/success')
        loading.value = false
      })
      .catch((error: unknown) => {
        console.error(error)
        onErrorShared(error)
        loading.value = false
      })
  }

  return {
    data,
    cateringOrdersToDo,
    cateringOrdersCompany,
    loading,
    unsubscribe1,
    unsubscribe2,
    resetState,
    getMessages,
    getCateringOrdersToDo,
    getCompanyCateringOrders,
    updateMessageState,
    updateCateringOrder,
  }
})
