import i18n from '~/i18n'

const TIMEOUT = 6000

function initialState() {
  return {
    visible: false,
    color: 'primary',
    text: 'works',
    timeout: 6000,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
  // @ts-expect-error
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    // @ts-expect-error
    showSnackbar({ commit }, { color: snackColor, text: snackText, timeout: snackTimeout }) {
      commit(
        'SHOW_SNACKBAR',
        {
          color: snackColor || 'primary',
          text: snackText || i18n.t('FormView.saved'),
          timeout: snackTimeout || 6000,
        },
      )
      setTimeout(
        () => {
          commit('HIDE_SNACKBAR')
        },
        TIMEOUT,
      )
    },
    // @ts-expect-error
    showSnackbarInfinity({ commit }, { color: snackColor, text: snackText }) {
      commit(
        'SHOW_SNACKBAR',
        {
          color: snackColor || 'primary',
          text: snackText || i18n.t('FormView.saved'),
          timeout: -1,
        },
      )
    },
    // @ts-expect-error
    hideSnackbar({ commit }) {
      commit('HIDE_SNACKBAR')
    },
  },
  mutations: {
    RESET_STATE(_state: any) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    // @ts-expect-error
    SHOW_SNACKBAR(_state, { color: snackColor, text: snackText, timeout: snackTimeout }) {
      _state.color = snackColor
      _state.text = snackText
      _state.timeout = snackTimeout
      _state.visible = true
    },
    HIDE_SNACKBAR(_state: any) {
      _state.timeout = 6000
      _state.visible = false
    },
  },
}
