import Vue from 'vue'
import VueRouter from 'vue-router'
import SignOutGuard from '../guards/signOutGuard'
import { auth } from '../firebaseCore'

Vue.use(VueRouter)

const metaModerator = {
  requiresAuth: true,
  moderator: true,
}

const metaCompany = {
  requiresAuth: true,
  owner: true,
  deliver: true,
  manager: true,
}

const metaCustomerManager = {
  requiresAuth: true,
  customer: true,
}

export const routes = [
  {
    path: '/sign-out',
    name: 'sign-out',
    beforeEnter: SignOutGuard,
  },
  {
    path: '*',
    component: () => import('../views/error/TheError404.vue'),
  },
  {
    path: '/m',
    name: 'mobile-redirect',
    component: () => import('../views/guest/MobileRedirect.vue'),
  },
  {
    path: '/error-deliver',
    name: 'error-deliver',
    component: () => import('../views/guest/ErrorDeliver.vue'),
  },
  {
    path: '/error-active-account',
    name: 'error-active-account',
    component: () => import('../views/guest/ErrorActiveAccount.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/guest/LoginView.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/guest/ResetPasswordView.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/guest/ContactView.vue'),
  },
  {
    path: '/auth',
    name: 'authView',
    component: () => import('../views/guest/AuthView.vue'),
  },
  {
    path: '/moderator',
    name: 'moderator',
    component: () => import('../views/moderator/ModeratorView.vue'),
    meta: metaModerator,
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import('../views/moderator/MessagesView.vue'),
    meta: metaModerator,
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import('../views/moderator/CompaniesView.vue'),
    meta: metaModerator,
  },
  {
    path: '/payment-last-working-day',
    name: 'payment-last-working-day',
    component: () => import('../views/company/PaymentsLastWorkingDayView.vue'),
    meta: metaCompany,
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/company/CompanyView.vue'),
    meta: {
      requiresAuth: true,
      owner: true,
    },
  },
  {
    path: '/catering-orders',
    name: 'catering-orders',
    component: () => import('../views/company/CateringOrdersView.vue'),
    meta: metaCompany,
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/company/NewsView.vue'),
    meta: metaCompany,
  },
  {
    path: '/new-users',
    name: 'new-users',
    component: () => import('../views/company/NewUsersView.vue'),
    meta: metaCompany,
  },
  {
    path: '/coupons',
    name: 'coupons',
    component: () => import('../views/company/CouponsView.vue'),
    meta: metaCompany,
  },
  {
    path: '/promo-code-statistics',
    name: 'promo-code-statistics',
    component: () => import('../views/company/PromoCodeStatisticsView.vue'),
    meta: metaCompany,
  },
  {
    path: '/promo-code-usage',
    name: 'promo-code-usage',
    component: () => import('../views/company/PromoCodeUsageView.vue'),
    meta: metaCompany,
  },
  {
    path: '/routes',
    name: 'routes',
    component: () => import('../views/company/RoutesView.vue'),
    meta: metaCompany,
  },
  {
    path: '/delivers-sells',
    name: 'deliversSells',
    component: () => import('../views/company/DeliversSellsView.vue'),
    meta: metaCompany,
  },
  {
    path: '/suppliers',
    name: 'suppliers',
    component: () => import('../views/company/SuppliersPage.vue'),
    meta: metaCompany,
  },
  {
    path: '/suppliersMap',
    name: 'suppliersMap',
    component: () => import('../views/company/SuppliersMapView.vue'),
    meta: metaCompany,
  },
  {
    path: '/employees',
    name: 'employees',
    component: () => import('../views/company/EmployeesView.vue'),
    meta: metaCompany,
  },
  {
    path: '/employeesRanking',
    name: 'employeesRanking',
    component: () => import('../views/company/EmployeesRankingView.vue'),
    meta: metaCompany,
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/company/OrdersView.vue'),
    meta: metaCompany,
  },
  {
    path: '/onlineTransactionReports',
    name: 'onlineTransactionReports',
    component: () => import('../views/company/OnlineTransactionReports.vue'),
    meta: metaCompany,
  },
  {
    path: '/ordersStatistic',
    name: 'ordersStatisticView',
    component: () => import('../views/company/OrdersStatisticView.vue'),
    meta: metaCompany,
  },
  {
    path: '/promotionsReports',
    name: 'promotionsReports',
    component: () => import('../views/company/PromotionsReports.vue'),
    meta: metaCompany,
  },
  {
    path: '/stats',
    name: 'statsView',
    component: () => import('../views/company/StatsView.vue'),
    meta: metaCompany,
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('../views/company/MenuView.vue'),
    meta: metaCompany,
  },
  {
    path: '/reportBugOrSuggestion',
    name: 'reportBugOrSuggestion',
    component: () => import('../views/company/ReportBugOrSuggestionView.vue'),
    meta: metaCompany,
  },
  {
    path: '/missingCompanies',
    name: 'missingCompanies',
    component: () => import('../views/company/MissingCompaniesView.vue'),
    meta: metaCompany,
  },
  {
    path: '/summary',
    name: 'summary',
    component: () => import('../views/SummaryView.vue'),
    meta: {
      requiresAuth: true,
      owner: true,
      manager: true,
      deliver: true,
    },
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: () => import('../views/CookiesView.vue'),
  },
  {
    path: '/menuReturn',
    name: 'menuReturn',
    component: () => import('../views/company/MenuReturnView.vue'),
    meta: {
      requiresAuth: true,
      owner: true,
      manager: true,
      deliver: true,
    },
  },
  {
    path: '/menuReturnReport',
    name: 'menuReturnReport',
    component: () => import('../views/company/MenuReturnViewReport.vue'),
    meta: {
      requiresAuth: true,
      owner: true,
      manager: true,
      deliver: true,
    },
  },
  {
    path: '/subsidy',
    name: 'subsidy',
    component: () => import('../views/company/SubsidyView.vue'),
    meta: metaCompany,
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('../views/company/InvoicesView.vue'),
    meta: metaCompany,
  },
  {
    path: '/suppliersSubsidy',
    name: 'suppliersSubsidy',
    component: () => import('../views/company/SuppliersSubsidyView.vue'),
    meta: metaCompany,
  },
  {
    path: '/customersSubsidy',
    name: 'customersSubsidy',
    component: () => import('../views/manager/CustomersSubsidyView.vue'),
    meta: metaCustomerManager,
  },
  {
    path: '/subsidyBudget',
    name: 'subsidyBudget',
    component: () => import('../views/manager/ManagerRoutes.vue'),
    meta: metaCustomerManager,
  },
  {
    path: '/regulation',
    name: 'terms',
    component: () => import('../views/RegulationsView.vue'),
  },
  {
    path: '/privacy',
    name: 'privacyPolicy',
    component: () => import('../views/PrivacyPolicyView.vue'),
  },
  {
    path: '/',
    name: 'landing',
    component: () => import('../views/landing/LandingView.vue'),
  },
  {
    path: '/catering',
    name: 'catering',
    component: () => import('../views/landing/CateringView.vue'),
  },
  {
    path: '/gastronomy',
    name: 'for-gastronomy',
    component: () => import('../views/landing/For-Gastronomy.vue'),
  },
  {
    path: '/transport',
    name: 'transport',
    component: () => import('../views/landing/TransportView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/landing/AboutView.vue'),
  },
  {
    path: '/benefit',
    name: 'benefit',
    component: () => import('../views/landing/BenefitsView.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  // @ts-expect-error checked
  base: import.meta.env.BASE_URL,
  routes,
})

router.beforeEach((to, _from, next) => {
  const requiresAuth = to.matched.some(xxx => xxx.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  }
  else {
    // @ts-expect-error checked
    const requiresAuthResult = to.matched.some(xxx => (xxx.meta.requiresAuth && xxx.meta[auth?.currentUser?.displayName])
      || !xxx.meta.requiresAuth)

    if (auth.currentUser && to.path === '/')
      next('/routes')

    if (requiresAuthResult)
      next()
    else
      next('/sign-out')
  }
})

export default router
