import type { DocumentData, DocumentReference } from 'firebase/firestore'
import { addDoc, deleteDoc, doc, getDoc, serverTimestamp, setDoc, updateDoc, writeBatch } from 'firebase/firestore'
import { getDownloadURL, ref as refStorage, uploadBytes, uploadString } from 'firebase/storage'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import {
  collectionCompanies,
  collectionMenuCategories,
  collectionSMS,
  collectionStatisticAccess,
} from '../firestoreWrappers'
import { ROLE } from '../helpers/roles'
import { USER_PREFERENCES } from '../helpers/statisticAccess'
import { createAndUploadImageCompany } from '../helpers/uploadImage'
import { createSMSDate } from '../helpers/time'
import { firestore, storage } from '../firebaseCore'
import { getIdFromRef } from '../helpers/getIdFromRef'
import type { AgreementModel } from '../models/AgreementModel'
import { AGREEMENT_STATUS } from '../models/AgreementModel'
import store from '.'
import type { CompanyModel } from '~/models/CompanyModel'
import { mapCompany } from '~/models/CompanyModel'
import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'

export const useCompanyStore = defineStore('company', () => {
  const company: Ref<CompanyModel | null> = ref(null)
  const createError: Ref<unknown | null> = ref(null)
  const createLoading: Ref<boolean> = ref(false)
  const fetchError: Ref<unknown | null> = ref(null)
  const fetchLoading: Ref<boolean> = ref(false)
  const agreement: Ref<AgreementModel | null> = ref(null)
  const agreementNew: Ref<AgreementModel | null> = ref(null)
  const agreementLoading: Ref<boolean> = ref(false)
  const agreementError: Ref<unknown | null> = ref(null)
  const acceptAgreementLoading: Ref<boolean> = ref(false)
  const acceptAgreementError: Ref<unknown | null> = ref(false)

  const resetState = () => {
    company.value = null
    createError.value = null
    createLoading.value = false
    fetchError.value = null
    fetchLoading.value = false
    agreement.value = null
    agreementNew.value = null
    agreementLoading.value = false
    agreementError.value = null
    acceptAgreementLoading.value = false
    acceptAgreementError.value = null
  }

  function getCompany(userDataDompany: DocumentReference) {
    fetchError.value = null
    fetchLoading.value = true

    const onCompanyFetch = (companyData: DocumentData) => {
      fetchLoading.value = false
      company.value = mapCompany(companyData)
    }

    const onCompanyError = (arrgError: unknown) => {
      console.error(arrgError)
      fetchLoading.value = false
      fetchError.value = arrgError

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(arrgError),
      )
    }

    getDoc(userDataDompany).then(onCompanyFetch).catch(onCompanyError)
  }

  function setCompany(companyData: any) {
    fetchLoading.value = false
    company.value = mapCompany(companyData)
  }

  function uploadAgreement(selectedFile: any, userData: any) {
    agreementLoading.value = true
    agreementError.value = null

    const newActiveFile = `${new Date().toISOString()}${selectedFile.name}`

    const onAgreementSaved = (newAgreement: AgreementModel | null) => () => {
      agreementLoading.value = false
      company.value = {
        ...company.value,
        // @ts-expect-error
        agreement: {
          ...company.value?.agreement || {},
          ...newAgreement,
        },
      }
      agreementError.value = null
    }

    const onUploadError = (uploadError: unknown) => {
      console.error(uploadError)
      agreementLoading.value = false
      agreementError.value = uploadError

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(uploadError),
      )
    }

    const onUploadSuccess = () => {
      getCompany(userData.company)
      // @ts-expect-error
        .then(() => {
          const acceptedByCopy = company.value?.agreement?.acceptedBy
          // @ts-expect-error
          acceptedByCopy[getIdFromRef(userData.reference)] = true
          const newAgreement = {
            acceptedBy: acceptedByCopy,
            activeFile: newActiveFile,
            status: AGREEMENT_STATUS.waitingModeratorAccept,
          }

          updateDoc(
            userData.company,
            {
              agreement: newAgreement,
              lastUpdateByUser: userData.reference,
              lastUpdateTime: serverTimestamp(),
            },
          )
          // @ts-expect-error
            .then(onAgreementSaved(newAgreement))
            .catch(onUploadError)
        })
        .catch(onUploadError)
    }

    const path = `${getIdFromRef(userData.company)}/docs/${newActiveFile}`

    uploadBytes(
      refStorage(
        storage,
        path,
      ),
      selectedFile,
    )
      .then(onUploadSuccess)
      .catch(onUploadError)
  }

  function getCompanyAgreement(userData: any) {
    agreementError.value = null
    agreementLoading.value = true

    const onAgreementURLFetch = (agreementUrl: AgreementModel | null) => {
      agreementLoading.value = false
      agreement.value = agreementUrl
    }

    const onAgreementError = (errorArg: unknown) => {
      console.error(errorArg)
      agreementLoading.value = false
      agreementError.value = errorArg
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(errorArg),
      )
    }
    const path = `${getIdFromRef(userData.company)}/docs/${
      company.value?.agreement?.activeFile
    }`

    getDownloadURL(refStorage(
      storage,
      path,
    ))
    // @ts-expect-error
      .then(onAgreementURLFetch)
      .catch(onAgreementError)
  }

  async function createCompany(newCompany: any, userData: any) {
    createLoading.value = true
    createError.value = null

    let createdCompanyRef: any

    let uploadedImage: any

    const onCreateError = (errorArg: unknown) => {
      console.error(errorArg)

      if (createdCompanyRef)
        deleteDoc(createdCompanyRef).then(() => {})

      if (uploadedImage && uploadedImage?.ref)
        deleteDoc(uploadedImage.ref).then(() => {})

      createLoading.value = false
      createError.value = errorArg

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(errorArg),
      )
    }

    try {
      newCompany.lastUpdateByUser = userData.reference
      newCompany.lastUpdateTime = serverTimestamp()

      const imgData = newCompany.logo
      newCompany.logo = null
      createdCompanyRef = await addDoc(
        collectionCompanies,
        {
          ...newCompany.toMap(),

          createdByUser: userData.reference,
        },
      )

      if (imgData) {
        const { imageUrl, imagePath, uploadedData } = await createAndUploadImageCompany(
          createdCompanyRef,
          imgData,
          storage,
        )

        newCompany.logo = imageUrl
        newCompany.logoPath = imagePath
        uploadedImage = uploadedData
      }
      newCompany.reference = createdCompanyRef

      await updateDoc(
        userData.reference,
        {
          company: createdCompanyRef,

          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )

      const batch = writeBatch(firestore)

      batch.update(
        createdCompanyRef,
        {
          logo: newCompany.logo,
          logoPath: newCompany.logoPath,

          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )

      let menuCategoriesRef
      let statisticAccessRef
      let smsReference

      try {
        const companyID = getIdFromRef(createdCompanyRef)

        menuCategoriesRef = doc(
          collectionMenuCategories,
          companyID,
        )
        setDoc(
          menuCategoriesRef,
          {
            menuCategories: [],

            createdByUser: userData.reference,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )

        statisticAccessRef = doc(
          collectionStatisticAccess,
          companyID,
        )
        setDoc(
          statisticAccessRef,
          {
            access: [
              {
                role: ROLE.DELIVER,
                [USER_PREFERENCES]: false,
              },
              {
                role: ROLE.GET_DELIVER,
                [USER_PREFERENCES]: false,
              },
              {
                role: ROLE.MANAGER,
                [USER_PREFERENCES]: false,
              },
            ],

            createdByUser: userData.reference,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )

        const smsCollection = {
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        }

        // @ts-expect-error
        smsCollection[createSMSDate()] = 0
        smsCollection.lastUpdateByUser = userData.reference
        smsCollection.lastUpdateTime = serverTimestamp()

        smsReference = doc(
          collectionSMS,
          companyID,
        )
        setDoc(
          smsReference,
          smsCollection,
        )

        await batch.commit()

        createLoading.value = false
        company.value = newCompany
        createError.value = null

        store.dispatch(
          'app/updateUserData',
          {
            ...userData,
            company: createdCompanyRef,
          },
        )
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
      }
      catch (error: unknown) {
        const promises = [deleteDoc(uploadedImage.ref)]
        if (menuCategoriesRef)
          promises.push(deleteDoc(menuCategoriesRef))

        if (statisticAccessRef)
          promises.push(deleteDoc(statisticAccessRef))

        if (smsReference)
          promises.push(deleteDoc(smsReference))

        Promise.all(promises).finally(() => {
          createLoading.value = false
          createError.value = error

          console.error(error)
          store.dispatch(
            'snackbar/showSnackbar',
            errorDefault(error),
          )
        })
      }
    }
    catch (error: unknown) {
      onCreateError(error)
    }
  }

  function editCompany(editedCompany: any, userData: any) {
    createLoading.value = true
    createError.value = null

    const onEditSuccess = () => {
      createLoading.value = false
      company.value = editedCompany
      createError.value = null

      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
    }
    const onEditError = (editError: unknown) => {
      console.error(editError)
      createLoading.value = false
      createError.value = editError

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(editError),
      )
    }

    const updateCompanyData = (companyToUpdate: any) => {
      updateDoc(
        companyToUpdate.reference,
        {
          ...companyToUpdate.toMap(),
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      ).then(onEditSuccess).catch(onEditError)
    }

    const onImageUploaded = (uploadedImageUrl: any) => {
      editedCompany.logo = uploadedImageUrl
      updateCompanyData(editedCompany)
    }

    const onImageAdded = (uploadedImageRef: any) => {
      getDownloadURL(uploadedImageRef.ref).then(onImageUploaded)
    }

    if (typeof editedCompany.logo === 'string') {
      updateCompanyData(editedCompany)
    }
    else {
      uploadString(
        refStorage(
          storage,
          editedCompany.logoPath,
        ),
        editedCompany.logo.dataUrl,
        'data_url',
      )
        .then(onImageAdded)
        .catch(onEditError)
    }
  }

  return {
    company,
    createError,
    createLoading,
    fetchError,
    fetchLoading,
    agreement,
    agreementNew,
    agreementLoading,
    agreementError,
    acceptAgreementLoading,
    acceptAgreementError,
    resetState,
    getCompany,
    setCompany,
    uploadAgreement,
    getCompanyAgreement,
    createCompany,
    editCompany,
  }
})
