import type { DeliverModel } from './DeliverModel'

export class DeliverRankingModel {
  firstName: string | null
  lastName: string | null
  city: string | null
  clientsCount: number | null

  constructor(deliver: Partial<DeliverModel>, clientsCount: number) {
    this.firstName = deliver.profile?.firstName || null
    this.lastName = deliver.profile?.lastName || null
    // @ts-expect-error todo
    this.city = deliver.profile?.city || null
    this.clientsCount = clientsCount
  }
}
