export class PaymentModel {
  authorizationId: string | null
  cardRef: string | null
  orderId: string | null
  refundId: string | null
  saleId: string | null
  sessionId: string | null

  constructor(data: Partial<PaymentModel>) {
    this.authorizationId = data.authorizationId || null
    this.cardRef = data.cardRef || null
    this.orderId = data.orderId || null
    this.refundId = data.refundId || null
    this.saleId = data.saleId || null
    this.sessionId = data.sessionId || null
  }

  toMap() {
    return {
      authorizationId: this.authorizationId,
      cardRef: this.cardRef,
      orderId: this.orderId,
      refundId: this.refundId,
      saleId: this.saleId,
      sessionId: this.sessionId,
    }
  }
}
