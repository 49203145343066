import { getDocs } from 'firebase/firestore'
import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { DeliverRankingModel } from '../models/DeliverRankingModel'
import {
  getSuppliersByDeliver,
  getUsersBySuppliers,
} from '../firestoreWrappers'

export const useStatisticsStore = defineStore('statistics', () => {
  const deliversRanking: Ref<DeliverRankingModel[] | null> = ref(null)
  const fetchLoading: Ref<boolean> = ref(false)
  const fetchError: Ref<unknown | null> = ref(null)

  const resetState = () => {
    deliversRanking.value = null
    fetchLoading.value = false
    fetchError.value = null
  }

  const getDeliversRanking = async (delivers: any, getDelivers: any) => {
    fetchLoading.value = true
    fetchError.value = null
    const deliverList = [...delivers, ...getDelivers]
    const ranking: DeliverRankingModel[] = []

    try {
      for (const deliver of deliverList) {
        let clientsCount = 0
        const suppliersList = await getDocs(getSuppliersByDeliver(deliver.reference))
        for (const supplier of suppliersList.docs) {
          const userList = await getDocs(getUsersBySuppliers(supplier.ref))
          clientsCount += userList.docs.length
        }
        ranking.push(new DeliverRankingModel(deliver, clientsCount))
      }
      deliversRanking.value = ranking
    }
    catch (error: unknown) {
      fetchError.value = error
    }
    finally {
      fetchLoading.value = false
    }
  }

  return {
    deliversRanking,
    fetchLoading,
    fetchError,
    resetState,
    getDeliversRanking,
  }
})
