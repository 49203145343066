import OrdersModule from './orders'
import SuppliersModule from './suppliers'

export function regiesterRest(store: any) {
  store.registerModule(
    'orders',
    OrdersModule,
  )
  store.registerModule(
    'suppliers',
    SuppliersModule,
  )
}
