import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { errorDefault } from '../helpers/snackbar'
import type { MissingCompanyModel } from '../models/MissingCompanyModel'
import { mapMissingCompany } from '../models/MissingCompanyModel'
import { getMissingCompanies, updatetMissingCompany } from '../firebase/firestoreMissingCompanies'
import store from '.'
import type { DocumentReference } from 'firebase/firestore'

function mapMissingCompanies(item: MissingCompanyModel, status: string) {
  return (missingCompany: MissingCompanyModel) => (missingCompany?.reference?.id === item?.reference?.id
    ? {
        ...missingCompany,
        status,
      }
    : missingCompany
  )
}

export const useMissingCompaniesStore = defineStore('missingCompanies', () => {
  const missingCompanies: Ref<MissingCompanyModel[] | null> = ref(null)

  const resetState = () => {
    missingCompanies.value = null
  }

  const getMmissingCompanies = async (companyRef: DocumentReference) => {
    store.dispatch('shared/init')
    try {
      const response = await getMissingCompanies(companyRef)
      missingCompanies.value = response.docs.map(mapMissingCompany)
      store.dispatch('shared/success')
    }
    catch (err: unknown) {
      console.error(err)
      store.dispatch('snackbar/showSnackbar', errorDefault(err))
    }
  }

  const updateMmissingCompanyState = async (item: MissingCompanyModel, status: string, userRef: DocumentReference) => {
    store.dispatch('shared/init')
    try {
      await updatetMissingCompany(item, status, userRef)
      // @ts-expect-error
      missingCompanies.value = missingCompanies.value?.map(mapMissingCompanies(item, status)) || null
      store.dispatch('shared/success')
    }
    catch (err) {
      store.dispatch('snackbar/showSnackbar', errorDefault(err))
    }
  }

  return {
    missingCompanies,
    resetState,
    getMmissingCompanies,
    updateMmissingCompanyState,
  }
})
