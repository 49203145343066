import type { DocumentReference } from 'firebase/firestore'
import { collection, doc, getDoc, orderBy, query, Timestamp, where } from 'firebase/firestore'
import { PAYMENT_METHODS_SUBSIDY } from './store/orders'
import { firestore } from '~/firebaseCore'
import { getIdFromRef } from '~/helpers/getIdFromRef'
import { removePolishLetters } from '~/helpers/text'
import { ROLE } from '~/helpers/roles'

export const collectionNotificationRaports = collection(
  firestore,
  'notificationRaports',
)
export const collectionMenu = collection(
  firestore,
  'menu',
)
export const collectionRoutes = collection(
  firestore,
  'routes',
)
export const collectionMenuCategories = collection(
  firestore,
  'menuCategories',
)
export const collectionOrders = collection(
  firestore,
  'orders',
)
export const collectionUsers = collection(
  firestore,
  'users',
)
export const collectionManagers = collection(
  firestore,
  'managers',
)
export const collectionDelivers = collection(
  firestore,
  'delivers',
)
export const collectionOwners = collection(
  firestore,
  'owners',
)
export const collectionModerators = collection(
  firestore,
  'moderators',
)
export const collectionSMS = collection(
  firestore,
  'sms',
)
export const collectionStatisticAccess = collection(
  firestore,
  'statisticAccess',
)
export const collectionCompanies = collection(
  firestore,
  'companies',
)
export const collectionUpdateApplication = collection(
  firestore,
  'updateApplication',
)
export const collectionMenuReturn = collection(
  firestore,
  'menuReturns',
)
export function promoCodeTermsQuery() {
  return getDoc(doc(
    collectionUpdateApplication,
    'promoCodeTerms',
  ))
}

export function recommendationProgramQuery() {
  return getDoc(doc(
    collectionUpdateApplication,
    'recommendationProgram',
  ))
}

export const collectionSupplier = collection(
  firestore,
  'supplier',
)
export const collectionOrdersStatistic = collection(
  firestore,
  'ordersStatistics',
)
export const reportBugOrSuggestionCollection = collection(
  firestore,
  'reportBugOrSuggestion',
)

export function getOwner(uid: string) {
  return getDoc(doc(
    collectionOwners,
    uid,
  ))
}

export function getDeliver(uid: string) {
  return getDoc(doc(
    collectionDelivers,
    uid,
  ))
}
export function getManager(uid: string) {
  return getDoc(doc(
    collectionManagers,
    uid,
  ))
}
export function getModerator(uid: string) {
  return getDoc(doc(
    collectionModerators,
    uid,
  ))
}
export function getCustomer(uid: string) {
  return getDoc(doc(
    collectionUsers,
    uid,
  ))
}

export function getLandingCompanies() {
  return query(
    collectionCompanies,
    where(
      'isActive',
      '==',
      true,
    ),
  )
}

export function statisticAccessDoc(companyRef: string) {
  return getDoc(doc(
    collectionStatisticAccess,
    companyRef,
  ),
  )
}

export function deliver(company: DocumentReference) {
  return query(
    collectionDelivers,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'role',
      '==',
      ROLE.DELIVER,
    ),
  )
}

export function getdeliver(company: DocumentReference) {
  return query(
    collectionDelivers,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'role',
      '==',
      ROLE.GET_DELIVER,
    ),
  )
}

export function getBillers(company: DocumentReference) {
  return query(
    collectionDelivers,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'role',
      '==',
      ROLE.BILLER,
    ),
  )
}

export function getActiveDelivers(companyRef: DocumentReference) {
  return query(
    collectionDelivers,
    where(
      'company',
      '==',
      companyRef,
    ),
    where(
      'isActive',
      '==',
      true,
    ),
  )
}

export function manager(company: DocumentReference) {
  return query(
    collectionManagers,
    where(
      'company',
      '==',
      company,
    ),
  )
}

export function owner(company: DocumentReference) {
  return query(
    collectionOwners,
    where(
      'company',
      '==',
      company,
    ),
  )
}

export function menuByCategoryRef(companyRef: DocumentReference, menuCategoryID: string, isActive: boolean) {
  return query(
    collectionMenu,
    where(
      'companyRef',
      '==',
      companyRef,
    ),
    where(
      'categoryID',
      '==',
      menuCategoryID,
    ),
    where(
      'isActive',
      '==',
      isActive,
    ),
  )
}

export function getMenuByDay(companyRef: DocumentReference, day: string) {
  return query(
    collectionMenu,
    where(
      'companyRef',
      '==',
      companyRef,
    ),
    where(
      'isActive',
      '==',
      true,
    ),
    where(
      'activeDays',
      'array-contains',
      day,
    ),
  )
}

export function getRoutesQuery(company: DocumentReference) {
  return query(
    collectionRoutes,
    where(
      'company',
      '==',
      company,
    ),
  )
}

export function getRoutesByCities(company: DocumentReference, cities: string[]) {
  return query(
    collectionRoutes,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'city',
      'in',
      cities,
    ),
  )
}

export function menuCategoriesRef(company: DocumentReference) {
  return doc(
    collectionMenuCategories,
    getIdFromRef(company),
  )
}

export function ordersDate(company: DocumentReference, dateStart: Timestamp, dateEnd: Timestamp, route: DocumentReference) {
  return query(
    collectionOrders,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'route',
      '==',
      route,
    ),
    where(
      'date',
      '>=',
      dateStart,
    ),
    where(
      'date',
      '<',
      dateEnd,
    ),
  )
}

export function ordersSubsidy(company: DocumentReference, dateStart: Timestamp, dateEnd: Timestamp, supplierRef: DocumentReference) {
  return query(
    collectionOrders,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'paymentMethod',
      'in',
      PAYMENT_METHODS_SUBSIDY,
    ),
    where(
      'completementStatus',
      '==',
      'completed',
    ),
    where(
      'supplier',
      '==',
      supplierRef,
    ),
    where(
      'date',
      '>',
      dateStart,
    ),
    where(
      'date',
      '<',
      dateEnd,
    ),
  )
}

export function ordersOnlinePayment(company: DocumentReference, dateStart: Timestamp, dateEnd: Timestamp) {
  return query(
    collectionOrders,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'paymentStatus',
      'in',
      [
        'success',
        'partialRefund',
      ],
    ),
    where(
      'date',
      '>',
      dateStart,
    ),
    where(
      'date',
      '<',
      dateEnd,
    ),
  )
}

export function ordersSalesPercentage(company: DocumentReference, dateStart: Timestamp, dateEnd: Timestamp, data: number[]) {
  return query(
    collectionOrders,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'completementStatus',
      '==',
      'completed',
    ),
    where(
      'salesPercentage',
      'in',
      data,
    ),
    where(
      'date',
      '>',
      dateStart,
    ),
    where(
      'date',
      '<',
      dateEnd,
    ),
  )
}

export function ordersCompletedDateRange(company: DocumentReference, dateStart: Timestamp, dateEnd: Timestamp) {
  return query(
    collectionOrders,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'completementStatus',
      '==',
      'completed',
    ),
    where(
      'date',
      '>',
      dateStart,
    ),
    where(
      'date',
      '<',
      dateEnd,
    ),
  )
}

export function ordersFixedSales(company: DocumentReference, dateStart: Timestamp, dateEnd: Timestamp) {
  return query(
    collectionOrders,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'completementStatus',
      '==',
      'completed',
    ),
    where(
      'isSales',
      '==',
      true,
    ),
    where(
      'date',
      '>',
      dateStart,
    ),
    where(
      'date',
      '<',
      dateEnd,
    ),
  )
}

export function ordersSalesPercentageSecond(company: DocumentReference, dateStart: Timestamp, dateEnd: Timestamp) {
  return query(
    collectionOrders,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'completementStatus',
      '==',
      'completed',
    ),
    where(
      'salesPercentage',
      'in',
      [
        0.5,
        0.55,
        0.6,
        0.65,
        0.7,
        0.75,
        0.8,
        0.85,
        0.9,
        0.95,
      ],
    ),
    where(
      'date',
      '>',
      dateStart,
    ),
    where(
      'date',
      '<',
      dateEnd,
    ),
  )
}

export function ordersLoyaltyProgram(company: DocumentReference, dateStart: Timestamp, dateEnd: Timestamp) {
  return query(
    collectionOrders,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'completementStatus',
      '==',
      'completed',
    ),
    where(
      'isLoyaltyProgram',
      '==',
      true,
    ),
    where(
      'date',
      '>',
      dateStart,
    ),
    where(
      'date',
      '<',
      dateEnd,
    ),
  )
}

export function ordersIsNewUser(company: DocumentReference, dateStart: Timestamp, dateEnd: Timestamp) {
  return query(
    collectionOrders,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'completementStatus',
      '==',
      'completed',
    ),
    where(
      'orderByRecommendation.isNewUser',
      '==',
      true,
    ),
    where(
      'date',
      '>',
      dateStart,
    ),
    where(
      'date',
      '<',
      dateEnd,
    ),
  )
}

export function ordersToUpdate(company: DocumentReference, reference: DocumentReference) {
  return query(
    collectionOrders,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'route',
      '==',
      reference,
    ),
    where(
      'progress',
      '==',
      'processing',
    ),
  )
}

export function ordersStatistic(company: DocumentReference, dateStart: Timestamp, dateEnd: Timestamp) {
  return query(
    collectionOrders,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'completementStatus',
      'in',
      [
        'completed',
        'waitingFirmAccept',
        'waitingCustomerAccept',
      ],
    ),
    where(
      'date',
      '>=',
      dateStart,
    ),
    where(
      'date',
      '<',
      dateEnd,
    ),
  )
}

export function currentOrdersRef(reference: DocumentReference, company: DocumentReference) {
  return query(
    collectionOrders,
    where(
      'supplier',
      '==',
      reference,
    ),
    where(
      'company',
      '==',
      company,
    ),
    where(
      'progress',
      '==',
      'processing',
    ),
  )
}

export function getLockedUsers(reference: DocumentReference) {
  return query(
    collectionUsers,
    where(
      'work.suppliers',
      'array-contains',
      reference,
    ),
    where(
      'ordersAvailable',
      '==',
      false,
    ),
  )
}

export function getUsersBySuppliers(reference: DocumentReference) {
  return query(
    collectionUsers,
    where(
      'work.suppliers',
      'array-contains',
      reference,
    ),
  )
}

export function getSuppliersByDeliver(reference: DocumentReference) {
  return query(
    collectionSupplier,
    where(
      'deliverID',
      '==',
      reference,
    ),
  )
}

export function getSuppliiersByCode(code: number) {
  return query(
    collectionSupplier,
    where(
      'code',
      '==',
      `${code}`,
    ),
  )
}

export function getSuppliersByCompanyRouteCity(company: DocumentReference, routeRef: DocumentReference, cities: string[]) {
  return query(
    collectionSupplier,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'route',
      '==',
      routeRef,
    ),
    where(
      'place.city',
      'in',
      cities,
    ),
  )
}

export function getSuppliersSearcheCity(company: DocumentReference, cities: string[], search: string) {
  return query(
    collectionSupplier,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'tags',
      'array-contains-any',
      [
        ...search,
        ...(cities.map(removePolishLetters)),
      ],
    ),
  )
}

export function getSuppliersSubsidyByCompanyRouteCity(company: DocumentReference, cities: string[]) {
  return query(
    collectionSupplier,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'subsidy',
      '!=',
      null,
    ),
    where(
      'place.city',
      'in',
      cities,
    ),
  )
}
export function getNotSentNotificationSuppliersByCompanyCity(company: DocumentReference, cities: string[]) {
  return query(
    collectionSupplier,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'isPublicOffice',
      '==',
      true,
    ),
    where(
      'isActive',
      '==',
      true,
    ),
    where(
      'notificationInfo.notificationCount',
      '==',
      0,
    ),
    where(
      'place.city',
      'in',
      cities,
    ),
  )
}

export function getNotSentNotificationSuppliersByCompanyCity2(company: DocumentReference, cities: string[]) {
  return query(
    collectionSupplier,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'isHomeDelivery',
      '==',
      false,
    ),
    where(
      'isActive',
      '==',
      true,
    ),
    where(
      'notificationInfo.notificationCount',
      '==',
      0,
    ),
    where(
      'place.city',
      'in',
      cities,
    ),
  )
}

export function getNotSentNotificationSuppliersByCompany(company: DocumentReference) {
  return query(
    collectionSupplier,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'isPublicOffice',
      '==',
      true,
    ),
    where(
      'isActive',
      '==',
      true,
    ),
    where(
      'notificationInfo.notificationCount',
      '==',
      0,
    ),
  )
}

export function getNotSentNotificationSuppliersByCompany2(company: DocumentReference) {
  return query(
    collectionSupplier,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'isHomeDelivery',
      '==',
      false,
    ),
    where(
      'isActive',
      '==',
      true,
    ),
    where(
      'notificationInfo.notificationCount',
      '==',
      0,
    ),
  )
}

export function getNotificationsByRouteDate(routeRef: DocumentReference, dateFrom: Date, dateTo: Date) {
  return query(
    collectionNotificationRaports,
    where(
      'route',
      '==',
      routeRef,
    ),
    where(
      'sendDate',
      '>=',
      Timestamp.fromDate(dateFrom),
    ),
    where(
      'sendDate',
      '<',
      Timestamp.fromDate(dateTo),
    ),
    orderBy('sendDate', 'desc'),
  )
}

export function getSuppliersByCompanyRoute(company: DocumentReference, routeRef: DocumentReference) {
  return query(
    collectionSupplier,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'route',
      '==',
      routeRef,
    ),
  )
}

export function getSuppliersSearch(company: DocumentReference, search: string) {
  return query(
    collectionSupplier,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'tags',
      'array-contains-any',
      search,
    ),
  )
}

export function getSuppliersSubsidyByCompanyRoute(company: DocumentReference) {
  return query(
    collectionSupplier,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'subsidy',
      '!=',
      null,
    ),
  )
}

export function getSuppliersSubsidyByRoute(routeRef: DocumentReference) {
  return query(
    collectionSupplier,
    where(
      'route',
      '==',
      routeRef,
    ),
    orderBy(
      'arrivalTime',
      'desc',
    ),
  )
}

export function getSupplierFromHomeDeliveryCity(company: DocumentReference, cities: string[]) {
  return query(
    collectionSupplier,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'isHomeDelivery',
      '==',
      true,
    ),
    where(
      'place.city',
      'in',
      cities,
    ),
  )
}

export function getSupplierFromHomeDelivery(company: DocumentReference) {
  return query(
    collectionSupplier,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'isHomeDelivery',
      '==',
      true,
    ),
  )
}

export function getCollectionOrdersStatistic(company: DocumentReference, startDate: Timestamp, endDate: Timestamp, city: string) {
  return query(
    collectionOrdersStatistic,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'city',
      '==',
      city,
    ),
    where(
      'date',
      '>=',
      startDate,
    ),
    where(
      'date',
      '<',
      endDate,
    ),
  )
}
export function getSingleOrdersStatistic(company: DocumentReference, city: string, date: Timestamp) {
  return query(
    collectionOrdersStatistic,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'city',
      '==',
      city,
    ),
    where(
      'date',
      '==',
      date,
    ),
  )
}

export function getReportBugOrSuggestionActive(company: DocumentReference) {
  return query(
    reportBugOrSuggestionCollection,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'status',
      'in',
      [
        'active',
        'solved',
        'waitingCustomerAnswer',
        'toDo',
        'inProgress',
        'done',
      ],
    ),
  )
}

export function getReportBugOrSuggestionActiveByCitiesActive(company: DocumentReference, cities: string[]) {
  return query(
    reportBugOrSuggestionCollection,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'place.city',
      'in',
      cities,
    ),
    where(
      'status',
      '==',
      'active',
    ),
  )
}

export function getReportBugOrSuggestionActiveByCitiesSolved(company: DocumentReference, cities: string[]) {
  return query(
    reportBugOrSuggestionCollection,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'place.city',
      'in',
      cities,
    ),
    where(
      'status',
      '==',
      'solved',
    ),
  )
}
export function getReportBugOrSuggestionActiveByCitiesWaitingCustomerAnswer(company: DocumentReference, cities: string[]) {
  return query(
    reportBugOrSuggestionCollection,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'place.city',
      'in',
      cities,
    ),
    where(
      'status',
      '==',
      'waitingCustomerAnswer',
    ),
  )
}

export function getReportWithoutCities(company: DocumentReference) {
  return query(
    reportBugOrSuggestionCollection,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'place',
      '==',
      null,
    ),
    where(
      'status',
      'in',
      [
        'active',
        'solved',
        'waitingCustomerAnswer',
      ],
    ),
  )
}
