export class OrdersRatingModel {
  deliverScore: number | null
  foodScore: number | null
  isNotFollowRules: number | null
  ratingCount: number | null

  constructor(data: Partial<OrdersRatingModel>) {
    this.deliverScore = data?.deliverScore || 0
    this.foodScore = data?.foodScore || 0
    this.isNotFollowRules = data?.isNotFollowRules || 0
    this.ratingCount = data?.ratingCount || 0
  }

  toMap() {
    return {
      deliverScore: this.deliverScore,
      foodScore: this.foodScore,
      isNotFollowRules: this.isNotFollowRules,
      ratingCount: this.ratingCount,
    }
  }
}

export const mapOrdersStatistic = (data: Partial<OrdersRatingModel>) => new OrdersRatingModel(data)
