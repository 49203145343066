import type { DocumentData, DocumentReference, FieldValue } from 'firebase/firestore'
import { Timestamp } from 'firebase/firestore'
import { mapDateFromFirebaseFormat } from '../helpers/time'
import type { PlaceModel } from './PlaceModel'

export class ReportBugOrSuggestionModel {
  problemType: string | null
  email: string | null
  userRef: string | null
  status: string | null
  date: Timestamp | string | null
  user: string | null
  supplier: string | null
  place: PlaceModel | null
  deliver: string | null
  orderAddress: string | null
  lastUpdateByUser: DocumentReference | null
  priority: number | null
  problem: string | null
  company: DocumentReference | null
  lastUpdateTime: FieldValue | null
  reference: DocumentReference | null

  constructor(data: Partial<ReportBugOrSuggestionModel>, reference?: DocumentReference) {
    this.problemType = data.problemType || null
    this.email = data.email || null
    this.userRef = data.userRef || null
    this.status = data.status || null
    this.date = data.date instanceof Timestamp ? mapDateFromFirebaseFormat(data.date) : data.date || null
    this.user = data.user || null
    this.supplier = data.supplier || null
    this.place = data.place || null
    this.deliver = data.deliver || null
    this.orderAddress = data.orderAddress || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null
    this.priority = data.priority || null
    this.problem = data.problem || null
    this.company = data.company || null
    this.reference = reference || null
  }

  toMap() {
    return {
      problemType: this.problemType?.trim() || null,
      email: this.email?.trim() || null,
      userRef: this.userRef || null,
      status: this.status || null,
      date: this.date || null,
      user: this.user || null,
      supplier: this.supplier || null,
      place: this.place || null,
      deliver: this.deliver || null,
      orderAddress: this.orderAddress || null,
      priority: this.priority || null,
      problem: this.problem || null,
      company: this.company || null,

      lastUpdateTime: this.lastUpdateTime || null,
      lastUpdateByUser: this.lastUpdateByUser || null,
    }
  }
}

export function mapReportBugOrSuggestion(reportBugOrSuggestion: DocumentData) {
  return new ReportBugOrSuggestionModel(
    reportBugOrSuggestion.data(),
    reportBugOrSuggestion.ref,
  )
}
