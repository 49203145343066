import type { DocumentData } from 'firebase/firestore'
import { collection, deleteDoc, doc, getDoc, getDocs, query, serverTimestamp, where, writeBatch } from 'firebase/firestore'
import {
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signOut,
  updateProfile,
} from 'firebase/auth'
import { deliver, getBillers as getBiller, getdeliver, manager, owner } from '../firestoreWrappers'
import { errorDefault, errorEmail, savedDefault } from '../helpers/snackbar'
import { isBiller, isGetDeliver, ROLE } from '../helpers/roles'
import { auth, firestore, secondaryFirebaseApp } from '../firebaseCore'
import { generateRandomText } from '../helpers/generateRandomText'
import { getIdFromRef } from '../helpers/getIdFromRef'
import {
  mapDeliver,
  mapOwners,
} from '../models/ManagerModel'
import store from '.'
import { CompanyModel } from '~/models/CompanyModel'
import type { ManagerModel, OwnerModel } from '~/models/OwnerModel'
import { mapManagers } from '~/models/OwnerModel'
import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { DeliverModel } from '~/models/DeliverModel'

// @ts-expect-error
function sortByCities(query1, { 'profile': { cities } }) {
  return query(
    query1,
    where(
      'profile.cities',
      'array-contains-any',
      cities,
    ),
  )
}

export const useEmployeesStore = defineStore('employees', () => {
  const delivers: Ref<DeliverModel[] | null> = ref(null)
  const managers: Ref<ManagerModel[] | null> = ref(null)
  const owners: Ref<OwnerModel[] | null> = ref(null)
  const getdelivers: Ref<DeliverModel[] | null> = ref(null)
  const billers: Ref<any[] | null> = ref(null)
  const resetPasswordLoading: Ref<boolean> = ref(false)
  const resetPasswordError: Ref<unknown | null> = ref(null)
  const fetchLoading: Ref<boolean> = ref(false)
  const fetchError: Ref<unknown | null> = ref(null)
  const fetchDeliversLoading: Ref<boolean> = ref(false)
  const fetchDeliversError: Ref<unknown | null> = ref(null)
  const fetchGetDeliversLoading: Ref<boolean> = ref(false)
  const fetchGetDeliversError: Ref<unknown | null> = ref(null)
  const createLoading: Ref<boolean> = ref(false)
  const createError: Ref<unknown | null> = ref(null)

  const resetState = () => {
    delivers.value = null
    managers.value = null
    owners.value = null
    getdelivers.value = null
    billers.value = null
    resetPasswordLoading.value = false
    resetPasswordError.value = null
    fetchLoading.value = false
    fetchError.value = null
    fetchDeliversLoading.value = false
    fetchDeliversError.value = null
    fetchGetDeliversLoading.value = false
    fetchGetDeliversError.value = null
    createLoading.value = false
    createError.value = null
  }

  function getGetDelivers(userData: any) {
    if (getdelivers.value)
      return

    fetchError.value = null
    fetchGetDeliversLoading.value = true
    fetchLoading.value = true

    const deliversLocal = [...(getdelivers.value || [])]

    let deliversQuery: any = getdeliver(userData.company)
    if (userData.role == ROLE.GET_DELIVER || userData.role == ROLE.MANAGER) {
      deliversQuery = sortByCities(
        deliversQuery,
        userData,
      )
    }

    const onSuccess = (deliversDataList: DocumentData) => {
      // @ts-expect-error
      deliversLocal.push(...deliversDataList.docs.map(mapDeliver))
      let data = deliversLocal
      if (isGetDeliver(userData.role)) {
        // @ts-expect-error
        const myObject = deliversLocal.find(item => item.reference.id === userData.reference.id)
        // @ts-expect-error
        data = data.filter(item => (item.visibilityAccess || []).map((item1: any) => item1.id).includes(userData.reference.id))
        data = [
          // @ts-expect-error
          ...data,
          // @ts-expect-error
          myObject,
        ]
      }
      if (isBiller(userData.role))
      // @ts-expect-error
        data = data.filter(item => (item.visibilityAccess || []).map((item1: any) => item1.id).includes(userData.reference.id))

      fetchLoading.value = false
      fetchGetDeliversLoading.value = false
      fetchError.value = null
      getdelivers.value = data
    }

    const onError = (error: unknown) => {
      fetchLoading.value = false
      fetchGetDeliversLoading.value = false
      fetchError.value = error
    }

    getDocs(deliversQuery).then(onSuccess).catch(onError)
  }

  function getGetDelivers2(userData: any) {
    if (getdelivers.value)
      return

    fetchError.value = null
    fetchGetDeliversLoading.value = true
    fetchLoading.value = true

    const deliversLocal = [...(getdelivers.value || [])]

    let deliversQuery: any = getdeliver(userData.company)
    if (userData.role == ROLE.GET_DELIVER || userData.role == ROLE.MANAGER) {
      deliversQuery = sortByCities(
        deliversQuery,
        userData,
      )
    }

    const onSuccess = (deliversDataList: any) => {
      if (isBiller(userData.role))
      // @ts-expect-error
        deliversLocal.push(...deliversDataList.docs.map(mapDeliver).filter(item => (item.visibilityAccess || []).map(item1 => item1.id).includes(userData.reference.id)))
      else
      // @ts-expect-error
        deliversLocal.push(...deliversDataList.docs.map(mapDeliver))

      fetchLoading.value = false
      fetchGetDeliversLoading.value = false
      fetchError.value = null
      getdelivers.value = deliversLocal
    }

    const onError = (error: unknown) => {
      console.error(error)
      fetchLoading.value = false
      fetchGetDeliversLoading.value = false
      fetchError.value = error
    }

    getDocs(deliversQuery).then(onSuccess).catch(onError)
  }

  function getDelivers(userData: any) {
    if (delivers.value)
      return

    fetchError.value = null
    fetchDeliversLoading.value = true
    fetchLoading.value = true

    let deliversQuery: any = deliver(userData.company)
    if (userData.role == ROLE.GET_DELIVER) {
      deliversQuery = sortByCities(
        deliversQuery,
        userData,
      )
    }

    getDocs(deliversQuery)
      .then((deliversDataList) => {
        const deliversLocal = [...(delivers.value || [])]
        deliversLocal.push(...deliversDataList.docs.map(mapDeliver))

        let data = deliversLocal
        if (isGetDeliver(userData.role) || isBiller(userData.role))

          data = data.filter(item => (item.visibilityAccess || []).map(item1 => item1.id).includes(userData.reference.id))

        fetchLoading.value = false
        fetchDeliversLoading.value = false
        fetchError.value = null
        delivers.value = data
      })
      .catch((error) => {
        console.error(error)
        fetchLoading.value = false
        fetchDeliversLoading.value = false
        fetchError.value = error
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      })
  }

  function getManagers(userData: any) {
    fetchError.value = null
    fetchLoading.value = true

    const onSuccess = (managersList: any) => {
      fetchLoading.value = false
      fetchError.value = null
      managers.value = managersList.docs.map(mapManagers)
    }

    const onError = (error: unknown) => {
      console.error(error)
      fetchLoading.value = false
      fetchError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    let managersQuery: any = manager(userData.company)

    if (userData.role == ROLE.GET_DELIVER || userData.role == ROLE.MANAGER) {
      managersQuery = sortByCities(
        managersQuery,
        userData,
      )
    }

    getDocs(managersQuery).then(onSuccess).catch(onError)
  }

  function getBillers(userData: any) {
    fetchError.value = null
    fetchLoading.value = true

    const onSuccess = (billersList: DocumentData) => {
      fetchLoading.value = false
      fetchError.value = null
      billers.value = billersList.docs.map(mapDeliver)
    }

    const onError = (error: unknown) => {
      console.error(error)
      fetchLoading.value = false
      fetchError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    let billersQuery: any = getBiller(userData.company)

    if (userData.role == ROLE.BILLER) {
      billersQuery = sortByCities(
        billersQuery,
        userData,
      )
    }

    getDocs(billersQuery).then(onSuccess).catch(onError)
  }

  function getOwners(userData: any) {
    fetchError.value = null
    fetchLoading.value = true

    const onSuccess = (ownersList: DocumentData) => {
      fetchLoading.value = false
      fetchError.value = null
      owners.value = ownersList.docs.map(mapOwners).filter((item: any) => !item.hidden)
    }

    const onError = (error: unknown) => {
      console.error(error)
      fetchLoading.value = false
      fetchError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    getDocs(owner(userData.company))
      .then(onSuccess)
      .catch(onError)
  }

  // @ts-expect-error
  async function editEmployee(employee, oldRole, userData) {
    createLoading.value = true
    createError.value = null

    const getCorrectData = (role: string) => {
      if (role === ROLE.GET_DELIVER)
        return getdelivers.value
      if (role === ROLE.DELIVER)
        return delivers.value
      if (role === ROLE.MANAGER)
        return managers.value
      if (role === ROLE.BILLER)
        return billers.value
      if (role === ROLE.OWNER)
        return owners.value
      return []
    }

    const newEmployees = [...(getCorrectData(employee.role) || [])]

    // @ts-expect-error
    const byRef = user => getIdFromRef(user.reference) == getIdFromRef(employee.reference)
    const employeeIndex = newEmployees.findIndex(byRef)
    try {
      const batch = writeBatch(firestore)

      batch.update(
        employee.reference,
        {
          ...employee.toMap(),
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )

      if (employee?.isOwner || employee?.isOwner === false) {
        const response = await getDoc(userData.company)
        const company = new CompanyModel(
          // @ts-expect-error
          response.data(),
          response.ref,
        )
        if (
          !company?.agreement
          || !Object.keys(company?.agreement.acceptedBy || {}).includes(getIdFromRef(employee.reference))
        ) {
          batch.update(
            // @ts-expect-error
            company.reference,
            {
              agreement: {
                ...(company?.agreement || {}),
                acceptedBy: {
                  // @ts-expect-error
                  ...(company?.agreement?.acceptedBy || {}),
                  [getIdFromRef(employee.reference)]: false,
                },
              },
              lastUpdateByUser: userData.reference,
              lastUpdateTime: serverTimestamp(),
            },
          )
        }
        else if (employee?.isOwner === false) {
          const agreementCopy = company?.agreement.acceptedBy
          // @ts-expect-error
          delete agreementCopy[getIdFromRef(employee.reference)]
          batch.update(
            // @ts-expect-error
            company.reference,
            {
              agreement: {
                ...(company?.agreement || {}),
                acceptedBy: agreementCopy,
              },
              lastUpdateByUser: userData.reference,
              lastUpdateTime: serverTimestamp(),
            },
          )
        }
      }
      await batch.commit()

      if (employeeIndex === -1)
        newEmployees.push(employee)
      else
        newEmployees[employeeIndex] = employee

      // @ts-expect-error
      const byRef1 = user => getIdFromRef(user.reference) !== getIdFromRef(employee.reference)

      createLoading.value = false

      if (employee.role === ROLE.GET_DELIVER)
        getdelivers.value = newEmployees
      if (employee.role === ROLE.DELIVER)
        delivers.value = newEmployees
      if (employee.role === ROLE.MANAGER)
        managers.value = newEmployees
      if (employee.role === ROLE.BILLER)
        billers.value = newEmployees
      if (employee.role === ROLE.OWNER)
        owners.value = newEmployees

      createError.value = null
      if (oldRole) {
        if (oldRole === ROLE.GET_DELIVER)
          getdelivers.value = [...(getdelivers.value || [])].filter(byRef1)
        if (oldRole === ROLE.DELIVER)
          delivers.value = [...(delivers.value || [])].filter(byRef1)
        if (oldRole === ROLE.MANAGER)
          managers.value = [...(managers.value || [])].filter(byRef1)
        if (oldRole === ROLE.BILLER)
          billers.value = [...(billers.value || [])].filter(byRef1)
        if (oldRole === ROLE.OWNER)
          owners.value = [...(owners.value || [])].filter(byRef1)

        // getCorrectData(oldRole) = [...(getCorrectData(oldRole) || [])].filter(byRef1)
      }

      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
      if (employee.role === ROLE.DELIVER) {
        // @ts-expect-error
        const isDeliver = user => user.role === ROLE.DELIVER
        const deliversTmp = newEmployees.filter(isDeliver)
        store.dispatch(
          'suppliers/setDelivers',
          { delivers: deliversTmp },
        )
      }
    }
    catch (error: unknown) {
      console.error(error)
      createLoading.value = false
      createError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorEmail(error),
      )
    }
  }

  function resetEmployeePassword(email: string) {
    resetPasswordLoading.value = true
    resetPasswordError.value = null

    const onSuccess = () => {
      resetPasswordLoading.value = false
      resetPasswordError.value = null

      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
    }
    const onError = (error: unknown) => {
      console.error(error)
      resetPasswordLoading.value = false
      resetPasswordError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }
    sendPasswordResetEmail(
      auth,
      email,
    )
      .then(onSuccess)
      .catch(onError)
  }

  // @ts-expect-error
  async function createEmployee(employee, userData) {
    createLoading.value = true
    createError.value = null

    const getCorrectData = (role: string) => {
      if (role === ROLE.GET_DELIVER)
        return getdelivers.value
      if (role === ROLE.DELIVER)
        return delivers.value
      if (role === ROLE.MANAGER)
        return managers.value
      if (role === ROLE.BILLER)
        return billers.value
      if (role === ROLE.OWNER)
        return owners.value
      return []
    }

    const employeesState = getCorrectData(employee.role)
    const newEmployees = employeesState?.length
      ? [...employeesState]
      : []
    let newEmployeeRef
    const batch = writeBatch(firestore)
    const password = generateRandomText()
    const userRole = employee.role === ROLE.GET_DELIVER || employee.role === ROLE.BILLER ? ROLE.DELIVER : employee.role
    let auth2
    try {
      auth2 = getAuth(secondaryFirebaseApp)

      await createUserWithEmailAndPassword(
        auth2,
        employee.profile.email,
        password,
      )
      await updateProfile(
        // @ts-expect-error
        auth2.currentUser,
        { displayName: userRole },
      )
      // @ts-expect-error
      await sendEmailVerification(auth2.currentUser)
      await sendPasswordResetEmail(
        auth2,
        employee.profile.email,
      )
      newEmployeeRef = doc(
        collection(
          firestore,
          `${userRole}s`,
        ),
        // @ts-expect-error
        auth2.currentUser.uid,
      )
      batch.set(
        newEmployeeRef,
        {
          ...employee.toMap(),
          createdByUser: userData.reference,
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )
      employee.reference = newEmployeeRef

      await batch.commit()
      newEmployees.push(employee)

      createLoading.value = false

      if (employee.role === ROLE.GET_DELIVER)
        getdelivers.value = newEmployees
      if (employee.role === ROLE.DELIVER)
        delivers.value = newEmployees
      if (employee.role === ROLE.MANAGER)
        managers.value = newEmployees
      if (employee.role === ROLE.BILLER)
        billers.value = newEmployees
      if (employee.role === ROLE.OWNER)
        owners.value = newEmployees

      createError.value = null

      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
      await signOut(auth2)
    }
    catch (error: unknown) {
      // @ts-expect-error
      if (auth2.currentUser)
      // @ts-expect-error
        await deleteUser(auth2.currentUser)

      if (newEmployeeRef)
        await deleteDoc(newEmployeeRef)

      createLoading.value = false
      createError.value = error
      console.error(error)
      store.dispatch(
        'snackbar/showSnackbar',
        errorEmail(error),
      )
    }
  }

  return {
    delivers,
    managers,
    owners,
    getdelivers,
    billers,
    resetPasswordLoading,
    resetPasswordError,
    fetchLoading,
    fetchError,
    fetchDeliversLoading,
    fetchDeliversError,
    fetchGetDeliversLoading,
    fetchGetDeliversError,
    createLoading,
    createError,
    resetState,
    getGetDelivers,
    getGetDelivers2,
    getDelivers,
    getManagers,
    getBillers,
    getOwners,
    editEmployee,
    resetEmployeePassword,
    createEmployee,
  }
})
