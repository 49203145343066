import type { FirebaseStorage } from 'firebase/storage'
import { getDownloadURL, ref, uploadString } from 'firebase/storage'
import { generateRandomText } from './generateRandomText'
import { getIdFromRef } from './getIdFromRef'
import type { DocumentReference } from 'firebase/firestore'

export async function createAndUploadImage(companyRef: DocumentReference, newRef: DocumentReference, imgData: { dataUrl: string }, firebaseStorage: FirebaseStorage, sufix = '') {
  const imagePath = `${getIdFromRef(companyRef)}/${getIdFromRef(newRef)}${generateRandomText() + sufix}`

  const uploadedData = await uploadString(
    ref(firebaseStorage, imagePath),
    imgData.dataUrl,
    'data_url',
  )

  const imageUrl = await getDownloadURL(uploadedData.ref)

  return {
    imageUrl,
    imagePath,
    uploadedData,
  }
}

export async function createAndUploadImageCompany(newRef: DocumentReference, imgData: { dataUrl: string }, firebaseStorage: FirebaseStorage) {
  const imagePath = `${getIdFromRef(newRef)}/logo${generateRandomText()}`

  const uploadedData = await uploadString(
    ref(firebaseStorage, imagePath),
    imgData.dataUrl,
    'data_url',
  )

  const imageUrl = await getDownloadURL(uploadedData.ref)

  return {
    imageUrl,
    imagePath,
    uploadedData,
  }
}
