import type { DocumentData } from 'firebase/firestore'
import { ROLE } from '../helpers/roles'
import { CompanyManagerModel } from './CompanyManagerModel'
import { CustomerModel } from './CustomerModel'
import { DeliverModel } from './DeliverModel'
import { ModeratorModel } from './ModeratorModel'
import { OwnerModel } from './OwnerModel'

export function mapOwners(owner: DocumentData) {
  return new OwnerModel(
    owner.data(),
    owner.ref,
  )
}

export function mapDeliver(deliver: DocumentData) {
  return new DeliverModel(
    deliver.data(),
    deliver.ref,
  )
}

export function mapToModel(currentUserData: DocumentData, displayName: string) {
  const currentUser = currentUserData.data()

  if (displayName == ROLE.CUSTOMER && currentUser.role == ROLE.MANAGER) {
    return new CustomerModel(
      currentUser,
      currentUserData.ref,
    )
  }

  switch (currentUser.role) {
    case ROLE.GET_DELIVER:
    case ROLE.BILLER:
      return new DeliverModel(
        currentUser,
        currentUserData.ref,
      )
    case ROLE.OWNER:
      return new OwnerModel(
        currentUser,
        currentUserData.ref,
      )
    case ROLE.MANAGER:
      return new CompanyManagerModel(
        currentUser,
        currentUserData.ref,
      )
    case ROLE.MODERATOR:
      return new ModeratorModel(
        currentUser,
        currentUserData.ref,
      )
    default:
      return { isActive: true }
  }
}
