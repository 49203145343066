import type { DocumentData, DocumentReference } from 'firebase/firestore'
import { addDoc, arrayRemove, arrayUnion, deleteDoc, getDoc, getDocs, serverTimestamp, updateDoc, writeBatch } from 'firebase/firestore'
import { ref as firebaseRef, getDownloadURL, uploadString } from 'firebase/storage'
import {
  collectionMenu,
  getMenuByDay,
  menuByCategoryRef,
  menuCategoriesRef as menuCategoriesRef2,
} from '../firestoreWrappers'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import { onCreate, onUpdate } from '../helpers/actions'
import { createAndUploadImage } from '../helpers/uploadImage'
import { firestore, storage } from '../firebaseCore'
import { generateRandomText } from '../helpers/generateRandomText'
import { getIdFromRef } from '../helpers/getIdFromRef'
import { mapMenuCategories, mapMenuModel, MenuCategoryModel } from '../models/MenuModel'
import store from '.'
import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'

const getIdRaw = (arg0: any) => arg0.id

export const useMenusStore = defineStore('menus', () => {
  const menuCategoriesRef: Ref<DocumentReference | null> = ref(null)
  const menuCategories: Ref<MenuCategoryModel[] | null> = ref(null)
  const menuCategoriesLoading: Ref<boolean> = ref(false)
  const menus: Ref<any | null> = ref(null)
  const menusInactive: Ref<any | null> = ref(null)
  const menuOfDay: Ref<{
    monday: any | null
    tuesday: any | null
    wednesday: any | null
    thursday: any | null
    friday: any | null
    saturday: any | null
    sunday: any | null
  } | null> = ref({
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
  })
  const fetchMenuOfDayLoading: Ref<boolean> = ref(false)
  const fetchMenuOfDayError: Ref<unknown | null> = ref(null)
  const fetchLoading: Ref<boolean> = ref(false)
  const fetchError: Ref<unknown | null> = ref(null)
  const createLoading: Ref<boolean> = ref(false)
  const createError: Ref<unknown | null> = ref(null)

  const resetState = () => {
    menuCategoriesRef.value = null
    menuCategories.value = null
    menuCategoriesLoading.value = false
    menus.value = null
    menusInactive.value = null
    menuOfDay.value = {
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null,
    }
    fetchMenuOfDayLoading.value = false
    fetchMenuOfDayError.value = null
    fetchLoading.value = false
    fetchError.value = null
    createLoading.value = false
    createError.value = null
  }

  function getMenuCategories(userData: any) {
    menuCategoriesLoading.value = true

    const handleCategoriesError = (error: unknown) => {
      console.error(error)
      menuCategoriesLoading.value = false
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    const handleCategoriesSuccess = (categoriesData: any) => {
      if (categoriesData.data().menuCategories?.length) {
        const tmpMenuCategories = mapMenuCategories(categoriesData)

        const sortedCategories = tmpMenuCategories.sort((a: any, b: any) => {
          return a?.priority - b?.priority
        })

        menuCategoriesLoading.value = false
        menuCategories.value = sortedCategories
        menuCategoriesRef.value = categoriesData.ref

        getMenu(0, true, userData)
      }
      else {
        menuCategoriesLoading.value = false
        menuCategories.value = []
        menuCategoriesRef.value = null
      }
    }

    getDoc(menuCategoriesRef2(userData.company))
      .then(handleCategoriesSuccess)
      .catch(handleCategoriesError)
  }

  function getMenu(categoryIndex: any, isActive: boolean, userData: any) {
    fetchError.value = null
    fetchLoading.value = true

    const handleGetMenuSuccess = (menuData: DocumentData) => {
      fetchLoading.value = false
      fetchError.value = null
      if (isActive) {
        menus.value = {
          ...menus.value || {},
          [categoryIndex]: menuData.docs.map(mapMenuModel),
        }
      }
      else {
        menusInactive.value = {
          ...menusInactive.value || {},
          [categoryIndex]: menuData.docs.map(mapMenuModel),
        }
      }
    }

    const handleGetMenuError = (error: unknown) => {
      console.error(error)
      fetchLoading.value = false
      fetchError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        error,
      )
    }

    getDocs(menuByCategoryRef(
      userData.company,
      // @ts-expect-error
      menuCategories.value[categoryIndex].id,
      isActive,
    ))
      .then(handleGetMenuSuccess)
      .catch(handleGetMenuError)
  }

  function getMenuFromDay(day: any, userData: any) {
    fetchMenuOfDayError.value = null
    fetchMenuOfDayLoading.value = true

    const handleDaySuccess = (dayData: DocumentData) => {
      fetchMenuOfDayLoading.value = false
      fetchMenuOfDayError.value = null
      // @ts-expect-error
      menuOfDay.value[day] = dayData.docs.map(mapMenuModel)
    }

    const handleDayError = (error: unknown) => {
      console.error(error)
      fetchMenuOfDayLoading.value = false
      fetchMenuOfDayError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        error,
      )
    }
    getDocs(getMenuByDay(
      userData.company,
      day,
    ))
      .then(handleDaySuccess)
      .catch(handleDayError)
  }

  async function createNewFood(menu: any, menuCategoryIndex: number, userData: any) {
    createError.value = null
    createLoading.value = true

    let newMenuRef: any
    let uploaded: any

    const onError = async (error: unknown) => {
      if (uploaded)
        await deleteDoc(uploaded.ref)

      if (newMenuRef)
        await deleteDoc(newMenuRef)

      createLoading.value = false
      createError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    try {
      // @ts-expect-error
      menu.categoryID = menuCategories.value[menuCategoryIndex].id
      menu.companyRef = userData.company
      menu.lastUpdateByUser = userData.reference
      menu.lastUpdateTime = serverTimestamp()

      const imgData = menu.image
      menu.image = null

      const imageHighData = menu.imageHigh
      menu.imageHigh = null

      const newMenuData = {
        ...menu.toMap(),
        ...onCreate(userData.reference),
      }

      newMenuRef = await addDoc(
        collectionMenu,
        newMenuData,
      )

      if (imgData) {
        const { imageUrl, imagePath, uploadedData } = await createAndUploadImage(
          userData.company,
          newMenuRef,
          imgData,
          storage,
        )

        menu.image = imageUrl
        menu.imagePath = imagePath
        uploaded = uploadedData
      }

      if (imageHighData) {
        const { imageUrl, imagePath, uploadedData } = await createAndUploadImage(
          userData.company,
          newMenuRef,
          imageHighData,
          storage,
          'High',
        )

        menu.imageHigh = imageUrl
        menu.imageHighPath = imagePath
        uploaded = uploadedData
      }

      const onSuccessUpdateMenu = () => {
        menu.menuRef = newMenuRef
        createLoading.value = false
        createError.value = null
        if (menu.isActive) {
          if (menus.value === null)
            menus.value = { 0: [menu] }
          else if (menus.value[menuCategoryIndex])
            menus.value[menuCategoryIndex].push(menu)
        }
        else if (menusInactive.value === null) {
          menus.value = [menu]
        }
        else if (menus.value[menuCategoryIndex]) {
          menusInactive.value[menuCategoryIndex].push(menu)
        }
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
      }

      const updateData = {
        image: menu.image,
        imagePath: menu.imagePath,
        ...onUpdate(userData.reference),
      }

      updateDoc(
        newMenuRef,
        updateData,
      )
        .then(onSuccessUpdateMenu)
        .catch(onError)
    }
    catch (error: unknown) {
      await onError(error)
    }
  }

  async function updateFood({ menu, menuCategoryIndex, changeStatus, userData }: any) {
    createError.value = null
    createLoading.value = true

    const onError = (error: unknown) => {
      console.error(error)
      createLoading.value = false
      createError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    const handleUpdateFood = (updatedMenuData: any, updatedCategoryIndex: any) => {
      const onUpdateSuccess = () => {
        const getIndex = () => {
          const byMenuRef = ({ menuRef }: any) => getIdFromRef(menuRef) == getIdFromRef(updatedMenuData.menuRef)

          if (menu.isActive) {
            const selectedMenu = changeStatus
              ? menusInactive.value
              : menus.value
            return selectedMenu
              ? selectedMenu[menuCategoryIndex].findIndex(byMenuRef)
              : null
          }
          const selectedMenu = changeStatus
            ? menus.value
            : menusInactive.value
          return selectedMenu
            ? selectedMenu[menuCategoryIndex].findIndex(byMenuRef)
            : null
        }

        const dishIndex = getIndex()

        createLoading.value = false
        createError.value = null
        if (dishIndex !== null) {
          if (updatedMenuData.isActive) {
            if (changeStatus) {
              menusInactive.value[updatedCategoryIndex].splice(
                dishIndex,
                1,
              )
            }
            else {
              const tmpMenus = menus.value
              const copy = [...tmpMenus[updatedCategoryIndex]]
              copy[dishIndex] = updatedMenuData
              tmpMenus[updatedCategoryIndex] = copy
              menus.value = tmpMenus
            }
          }
          else if (!updatedMenuData.isActive) {
            if (changeStatus) {
              menus.value[updatedCategoryIndex].splice(
                dishIndex,
                1,
              )
            }
            else {
              const tmpMenus = { ...menusInactive.value }
              tmpMenus[updatedCategoryIndex][dishIndex] = updatedMenuData
              menusInactive.value = tmpMenus
            }
          }
        }
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
      }

      menu.lastUpdateByUser = userData.reference
      menu.lastUpdateTime = serverTimestamp()

      updateDoc(
        menu.menuRef,
        menu.toUpdateMap(),
      )
        .then(onUpdateSuccess)
        .catch(onError)
    }

    if (!menu.image || typeof menu.image === 'string') {
      handleUpdateFood(
        menu,
        menuCategoryIndex,
      )
    }
    else if (!menu.imagePath && menu.image) {
      let uploaded: any
      let uploadedHigh: any
      try {
        const imagePath = `${getIdFromRef(userData.company)}/${getIdFromRef(menu.reference)}${generateRandomText()}`
        const imageHighPath = `${imagePath}High`

        menu.imagePath = imagePath
        menu.imageHighPath = imageHighPath

        uploaded = await uploadString(
          firebaseRef(
            storage,
            imagePath,
          ),
          menu.image.dataUrl,
          'data_url',
        )

        uploadedHigh = await uploadString(
          firebaseRef(
            storage,
            imageHighPath,
          ),
          menu.imageHigh.dataUrl,
          'data_url',
        )

        const imageUrl = await getDownloadURL(uploaded.ref)
        menu.image = imageUrl

        const imageHighUrl = await getDownloadURL(uploadedHigh.ref)
        menu.imageHigh = imageHighUrl

        handleUpdateFood(
          menu,
          menuCategoryIndex,
        )
      }
      catch (error: unknown) {
        if (uploaded)
          await deleteDoc(uploaded.ref)

        if (uploadedHigh)
          await deleteDoc(uploadedHigh.ref)

        onError(error)
      }
    }
    else {
      try {
        let uploadedHigh
        const uploaded = await uploadString(
          firebaseRef(storage, menu.imagePath),
          menu.image.dataUrl,
          'data_url',
        )

        if (menu.imageHighPath) {
          uploadedHigh = await uploadString(
            firebaseRef(storage, menu.imageHighPath),
            menu.imageHigh.dataUrl,
            'data_url',
          )
        }
        else {
          const imageHighPath = `${menu.imagePath}High`
          menu.imageHighPath = imageHighPath
          uploadedHigh = await uploadString(
            firebaseRef(storage, imageHighPath),
            menu.imageHigh.dataUrl,
            'data_url',
          )
        }

        const imageUrl = await getDownloadURL(uploaded.ref)
        const imageHighUrl = await getDownloadURL(uploadedHigh.ref)
        menu.image = imageUrl
        menu.imageHigh = imageHighUrl
        handleUpdateFood(
          menu,
          menuCategoryIndex,
        )
      }
      catch (error: unknown) {
        onError(error)
      }
    }
  }

  function createNewCategory(categoryName: string, categoryNameEN: string, active: boolean, selectedDate: any, userData: any) {
    createError.value = null
    createLoading.value = true

    const handleCategorySuccess = (newCategoryData: any) => () => {
      createLoading.value = false
      createError.value = null
      menuCategories.value?.push(newCategoryData)
      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
    }

    const handleCategoryError = (error: unknown) => {
      console.error(error)
      createLoading.value = false
      createError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    const handleCategoriesData = (menuCategoryData: any) => {
      const newCategoryData = new MenuCategoryModel(
        {
          name: categoryName,
          nameEN: categoryNameEN,
          isActive: active,
          orderOnDay: selectedDate,
          // @ts-expect-error
          id:
            menuCategoryData.data().menuCategories.length > 0
              ? Math.max(...menuCategoryData.data().menuCategories.map(getIdRaw)) + 1
              : 1,
        },
        menuCategoryData.ref,
      )

      updateDoc(
        menuCategoryData.ref,
        {
          menuCategories: arrayUnion(newCategoryData.toMap()),

          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )
        .then(handleCategorySuccess(newCategoryData))
        .catch(handleCategoryError)
    }

    getDoc(menuCategoriesRef2(userData.company))
      .then(handleCategoriesData)
      .catch(handleCategoryError)
  }

  async function changeActivity(data: any, isActive: boolean, selectedCategory: any, userData: any) {
    menuCategoriesLoading.value = true

    try {
      const batch = writeBatch(firestore)

      data.forEach((item: any) => {
        batch.update(
          item.menuRef,
          {
            isActive: !isActive,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )
      })

      await batch.commit()
      const getId = (item: any) => item.menuRef.id

      const moveMenusObjects: any = []
      const tmpMenus: any = isActive
        ? menus.value
        : menusInactive.value
      const menuRefs = data.map(getId)

      const filterAndUpdateMenuItems = (item: any) => {
        if (menuRefs.includes(item.menuRef.id)) {
          const tmpItem = item
          tmpItem.isActive = !isActive
          moveMenusObjects.push(tmpItem)
          return false
        }
        return true
      }

      tmpMenus[selectedCategory] = tmpMenus[selectedCategory]
        .filter(filterAndUpdateMenuItems)

      if (isActive)
        menus.value = tmpMenus
      else
        menusInactive.value = tmpMenus

      if (
        (isActive && menusInactive.value && menusInactive.value[selectedCategory])
        || (!isActive && menus.value && menus.value[selectedCategory])
      ) {
        const tmpInactive: any = isActive
          ? menusInactive.value
          : menus.value
        tmpInactive[selectedCategory] = [
          ...tmpInactive[selectedCategory],
          ...moveMenusObjects,
        ]
        if (isActive)
          menusInactive.value = tmpInactive
        else
          menus.value = tmpInactive
      }

      menuCategoriesLoading.value = false
    }
    catch {
      menuCategoriesLoading.value = false
    }
  }

  function editCategory(categoryName: string, categoryNameEN: string, categoryIndex: number, active: boolean, selectedDate: any, userData: any) {
    createError.value = null
    createLoading.value = true

    // @ts-expect-error
    const selectedMenuCategory = menuCategories.value[categoryIndex]

    const batch = writeBatch(firestore)

    batch.update(
      // @ts-expect-error
      selectedMenuCategory.menuCategoryRef,
      {
        menuCategories: arrayRemove(selectedMenuCategory.toMap()),
        lastUpdateByUser: userData.reference,
        lastUpdateTime: serverTimestamp(),
      },
    )

    const menuToUpdate = selectedMenuCategory
    menuToUpdate.name = categoryName
    menuToUpdate.nameEN = categoryNameEN
    menuToUpdate.isActive = active
    menuToUpdate.orderOnDay = selectedDate

    batch.update(
      // @ts-expect-error
      selectedMenuCategory.menuCategoryRef,
      {
        menuCategories: arrayUnion(menuToUpdate.toMap()),
        lastUpdateByUser: userData.reference,
        lastUpdateTime: serverTimestamp(),
      },
    )

    const onSuccess = () => {
      createLoading.value = false
      createError.value = null
      // @ts-expect-error
      menuCategories.value[categoryIndex] = menuToUpdate

      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
    }

    const onError = (error: unknown) => {
      console.error(error)
      createLoading.value = false
      createError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    batch.commit().then(onSuccess).catch(onError)
  }

  function updateCategoryOrder(categoryData: any, userData: any) {
    createError.value = null
    createLoading.value = true

    const handleOrderSuccess = () => {
      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
      createLoading.value = false
      createError.value = null
      menuCategories.value = categoryData
      menus.value = null
      menus.value = null
      window.location.reload()
    }

    const handleOrderError = (error: unknown) => {
      console.error(error)
      createLoading.value = false
      createError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }
    updateDoc(
      menuCategoriesRef.value as DocumentReference,
      {
        menuCategories: categoryData.map((item: any) => item.toMap()),
        lastUpdateByUser: userData.reference,
        lastUpdateTime: serverTimestamp(),
      },
    )
      .then(handleOrderSuccess)
      .catch(handleOrderError)
  }

  return {
    menuCategoriesRef,
    menuCategories,
    menuCategoriesLoading,
    menus,
    menusInactive,
    menuOfDay,
    fetchMenuOfDayLoading,
    fetchMenuOfDayError,
    fetchLoading,
    fetchError,
    createLoading,
    createError,
    resetState,
    getMenuCategories,
    getMenu,
    getMenuFromDay,
    createNewFood,
    updateFood,
    createNewCategory,
    changeActivity,
    editCategory,
    updateCategoryOrder,
  }
})
