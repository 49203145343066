import type { DocumentData, DocumentReference, GeoPoint, Timestamp } from 'firebase/firestore'
import { DocumentModel } from './DocumentModel'
import { PaymentModel } from './PaymentModel'
import { AddressModel } from './AddressModel'

interface IWork {
  arrivalTime: Timestamp
  address: string
  city: string
  description: string
  company: string
  employeesCount: number
  flor: number
  geoPoint: GeoPoint | null
  geoHash: string
  parking: boolean
}

export class CustomerModel extends DocumentModel {
  appVersion: string
  dislikeDishes: DocumentReference[]
  displayName: string
  email: string
  facebookUid: string | null
  favouriteDishes: DocumentReference[]
  home: AddressModel | null
  isActive: boolean
  languageCode: string
  notificationSettings: {
    communicationMethod: string
    morningOrdersReminding: boolean
    notificationDays: string[]
    notificationOrdersRemindingDays: string[]
    ordersReminding: boolean
    ordersRemindingTime: Timestamp | string | null
  }

  notificationToken: string | null
  ordersAvailable: boolean
  paymentId: PaymentModel | null
  phone: string
  preferences: any[]
  role: string
  work: IWork | null
  subsidyBudget: any
  isOrderWithSubsidy: boolean

  constructor(data: Partial<CustomerModel>, reference: DocumentReference | null) {
    super(
      data,
      reference,
    )

    this.appVersion = data.appVersion || ''
    this.dislikeDishes = data.dislikeDishes || []
    this.displayName = data.displayName || ''
    this.email = data.email || ''
    this.facebookUid = data.facebookUid || null
    this.favouriteDishes = data.favouriteDishes || []
    this.home = data.home ? new AddressModel(data.home) : null
    this.isActive = data.isActive || false
    this.languageCode = data.languageCode || 'pl'
    this.notificationSettings = data.notificationSettings || {
      communicationMethod: '',
      morningOrdersReminding: false,
      notificationDays: [],
      notificationOrdersRemindingDays: [],
      ordersReminding: false,
      ordersRemindingTime: null,
    }
    this.notificationToken = data.notificationToken || null
    this.ordersAvailable = data.ordersAvailable || false
    this.paymentId = data.paymentId ? new PaymentModel(data.paymentId) : null
    this.phone = data.phone || ''
    this.preferences = data.preferences || []
    this.role = data.role || ''
    this.work = data.work || null
    this.subsidyBudget = data.subsidyBudget === null || data.subsidyBudget === undefined ? null : data.subsidyBudget
    this.isOrderWithSubsidy = data.isOrderWithSubsidy || false
  }

  toMap() {
    return {
      ...super.toMap(),

      appVersion: this.appVersion,
      dislikeDishes: this.dislikeDishes,
      displayName: this.displayName,
      email: this.email,
      facebookId: this.facebookUid,
      favouriteDishes: this.favouriteDishes,
      home: this.home?.toMap() || null,
      isActive: this.isActive,
      languageCode: this.languageCode,
      notificationSettings: this.notificationSettings,
      notificationToken: this.notificationToken,
      ordersAvailable: this.ordersAvailable,
      paymentId: this.paymentId?.toMap(),
      phone: this.phone,
      preferences: this.preferences,
      role: this.role,
      work: this.work,
      subsidyBudget: this.subsidyBudget,
      isOrderWithSubsidy: this.isOrderWithSubsidy,
    }
  }
}

export function mapCustomers(manager: DocumentData) {
  return new CustomerModel(
    manager.data(),
    manager.ref,
  )
}
