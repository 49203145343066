import type { DocumentData, DocumentReference, FieldValue } from 'firebase/firestore'

export class PromoCodeStatistic {
  date: string | null
  promoCode: string | null
  email: string | null
  phone: string | null
  fixedSalesAmount: string | null
  salesPercentage: number | null
  createdByUser: DocumentReference | null
  lastUpdateByUser: DocumentReference | null
  lastUpdateTime: FieldValue | null
  reference: DocumentReference | null

  constructor(data: Partial<PromoCodeStatistic>, reference?: DocumentReference | null) {
    this.date = data.date || null
    this.promoCode = data.promoCode || null
    this.email = data.email || null
    this.phone = data.phone || null
    this.fixedSalesAmount = data.fixedSalesAmount || null
    this.salesPercentage = data.salesPercentage || null
    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null
    this.reference = reference || null
  }

  toMap() {
    return {
      date: this.date,
      promoCode: this.promoCode,
      email: this.email,
      phone: this.phone,
      fixedSalesAmount: this.fixedSalesAmount,
      salesPercentage: this.salesPercentage,

      lastUpdateTime: this.lastUpdateTime || null,
      lastUpdateByUser: this.lastUpdateByUser || null,
    }
  }
}

export function mapPromoCodeStatistic(data: DocumentData) {
  return new PromoCodeStatistic(
    data.data(),
    data.ref,
  )
}
